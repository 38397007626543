import React, { lazy, Suspense } from 'react';
//import { UnboundDefense } from './unbound';
import { Loading } from '../../components';
//import { NotFound } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
//import utils from '../../utils';

const Unbound = lazy(() => import('./unbound'));

export function UnboundLoading(props) {

  // if (utils.site.isNZ) {
  //   return <NotFound />
  // }

  return <div>
    <Suspense fallback={<Loading />}>
     <Unbound />
    </Suspense>
    <div className="unboundFooter">
      <ViewPartial/>
    </div>
  </div>
}