import * as React from 'react';
import ValidationAlert from '../components/ValidationAlert';
import utils from '../../../utils';
import { getLabel } from '../formRenderHelper';

import 'filepond-polyfill';

// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the plugin code
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';

// Register the plugin
registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);

function FilePondUploader({ control, handleChange, formOption }) {
    const [files, setFiles] = React.useState([])
    const { name, label, errors, style, filePond = {}, helpText, attrs } = control;
    //console.log(filePond)
    //const { template = 'bootstrap', usePlaceholder = false } = formOption;
    const fieldId = utils.random.shortId();
    const fieldLabel = getLabel(control, formOption);


    const ref = React.useRef(null);
    function handleInit() {
        console.log(ref.current);
    }

    control.customValueFn = (control) => {
        if (!ref.current) return [];
        return ref.current.getFiles().map(x => x.serverId);
    }

    const onUpdated = fileItems => {
        //console.log(fileItems, JSON.stringify(fileItems))
        // Set currently active file objects to this.state
        const newVal = fileItems.map(x => x.serverId)
        //console.log(newVal)
        handleChange({
            persist: () => { },
            target: { name: name, value: newVal, checked: false }
        }, control)
        setFiles(fileItems.map(fileItem => fileItem.file));
    }

    return (
        <div className={`ff ff--file form-group ${style && style.className}`}>
            {fieldLabel && <label htmlFor={fieldId} className="label">{fieldLabel}</label>}
            <div className="ff__input">

                <FilePond
                    ref={ref}
                    files={files}
                    allowMultiple={filePond.allowMultiple || true}
                    maxFileSize={filePond.maxFileSize || '10MB'}
                    maxFiles={filePond.maxFiles || 5}
                    server={{
                        process: filePond.serverProcess || utils.site.resourcePath('/api/filepond/upload'),
                        fetch: null,
                        revert: null
                    }}
                    oninit={() => handleInit()}
                    onupdatefiles={onUpdated}
                    labelIdle={filePond.labelIdle || `Drag & Drop your files or <span class="filepond--label-action"> Browse </span>`}
                    acceptedFileTypes={filePond.acceptedFileTypes || []}
                    allowImageResize={filePond.allowImageResize || false}
                    imageResizeTargetWidth={filePond.imageResizeTargetWidth || 2560}
                    imageResizeUpscale={filePond.imageResizeUpscale || false}
                    {...attrs}
                />
            </div>
            {helpText && <div className="ff__help" dangerouslySetInnerHTML={{ __html: helpText }}></div>}
            <ValidationAlert errors={errors} />
        </div>

    )
}

export default FilePondUploader;