import React, { lazy, Suspense } from "react";
import { Loading } from "../../components";
const CurlSecret = lazy(() => import("./curlSecret"));

export function CurlSecretLoadingV4QR(props) {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <CurlSecret />
      </Suspense>
    </div>
  );
}
