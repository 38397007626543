import React, { useState, useEffect } from "react";
import { SiteLink } from "../../components";
import { useStore } from "../../store";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { SearchBar } from "./searchBar";

export function NavBar(props) {
  const open = props.open;
  const currentLocation = props.currentLocation;
  const { state } = useStore();
  const menu = state.menu || { items: [] };
  const [openItemIndex, setOpenItemIndex] = useState(null);
  const handleMenuItemClick = (index) => {
    setOpenItemIndex(index === openItemIndex ? null : index);
  };

  const depth = 1;
  const menuItemElts = menu.items.map((item, index) => (
    <MenuItem
      item={item}
      itemNum={index}
      key={index}
      depth={depth}
      onCloseMenu={props.onCloseMenu}
      currentLocation={currentLocation}
      isOpen={index === openItemIndex}
      onItemClick={() => handleMenuItemClick(index)}
    ></MenuItem>
  ));
  return (
    <div className={`navBar ${open ? "navBar--on" : ""}`} id='sliding-menu'>
      <SearchBar onCloseMenu={props.onCloseMenu} />
      <div className='navBar__scrollArea' id='top-menu'>
        <nav className='navBar_nav'>
          <ul>{menuItemElts}</ul>
        </nav>
      </div>
    </div>
  );
}

function MenuItem(props) {
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const item = props.item;
  const subItems = item.items || [];
  const currentLocation = props.currentLocation;

  const subItemsElts =
    subItems.length > 0
      ? subItems.map((subItem, index) => (
          <SubMenuItem
            item={subItem}
            key={index}
            onCloseMenu={props.onCloseMenu}
            level={0}
          />
        ))
      : null;

  useEffect(() => {
    setSubMenuOpened(false);
  }, [currentLocation]);

  function closeMenu() {
    props.onCloseMenu();
  }

  function toggleSubmenu(e) {
    e.preventDefault();
    e.stopPropagation();
    setSubMenuOpened(!subMenuOpened);
    props.onItemClick();
  }

  if (item.url) {
    return (
      <li
        className={`navBar__item ${subMenuOpened ? "navBar__item--open" : ""}`}
      >
        <div className='navBar__link'>
          <SiteLink to={item.url} onClick={() => closeMenu()}>
            {item.title}
          </SiteLink>
          {subItemsElts && (
            <span className='navBar__icon' onClick={(e) => toggleSubmenu(e)}>
              {subMenuOpened ? <FaMinus /> : <FaPlus />}
            </span>
          )}
        </div>
        {subItemsElts && <ul className={`navBar__subMenu`}>{subItemsElts}</ul>}
      </li>
    );
  } else {
    return (
      <li
        className={`navBar__item ${props.isOpen ? "navBar__item--open" : ""}`}
      >
        <div
          className='navBar__link'
          onClick={(e) => {
            toggleSubmenu(e);
          }}
        >
          <span>{item.title}</span>
          {subItemsElts && (
            <span className='navBar__icon'>
              {props.isOpen ? <FaMinus /> : <FaPlus />}
            </span>
          )}
        </div>
        {subItemsElts && <ul className={`navBar__subMenu`}>{subItemsElts}</ul>}
      </li>
    );
  }
}

function SubMenuItem(props) {
  const item = props.item;
  const level = props.level + 1;
  const childItem = item.items || [];
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const childElts = childItem.map((item, index) => {
    return (
      <SubMenuItem
        key={index}
        item={item}
        onCloseMenu={props.onCloseMenu}
        level={level}
      />
    );
  });

  function closeMenu() {
    props.onCloseMenu();
  }

  function toggleSubmenu(e) {
    e.preventDefault();
    e.stopPropagation();
    setSubMenuOpened(!subMenuOpened);
  }

  return (
    <li
      className={`level__${level} navBar__subItem ${level > 1 && "sub__level"}`}
    >
      <SiteLink
        className='navBar__subLink'
        to={item.url}
        onClick={() => closeMenu()}
      >
        <span>{item.title}</span>
        {childElts && childElts.length > 0 && (
          <span
            className={`navBar__icon__sub ${
              subMenuOpened ? "navBar__icon__sub--open" : ""
            }`}
            onClick={(e) => toggleSubmenu(e)}
          >
            {" "}
            {subMenuOpened ? <FaMinus /> : <FaPlus />}{" "}
          </span>
        )}
      </SiteLink>
      {childElts && childElts.length > 0 && (
        <ul
          className={`level__${level + 1} navBar__subMenu ${
            level + 1 > 1 && "sub__level"
          } ${level + 1 > 6 && "over__level"} ${
            subMenuOpened ? "navBar__subMenu--open" : ""
          }`}
        >
          {childElts}
        </ul>
      )}
    </li>
  );
}
