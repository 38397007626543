import { css } from "@emotion/core";

export default {
  instagrams: css({
    marginLeft: "-10px",
    marginRight: "-10px",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  }),
  youtubes: css({
    marginLeft: "-10px",
    marginRight: "-10px",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  }),
  tiktoks: css({
    marginLeft: "-10px",
    marginRight: "-10px",
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  }),
  qa: {
    marginBottom: "1rem",
    borderBottom: "solid 1px #ccc",
  },
  qaQuestion: {
    fontWeight: "bold",
    marginBottom: "0.3rem",
  },
  qaAnswer: {},
};
