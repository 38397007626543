import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import { Item } from '../../items/item';

export function TestimonialItem(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  //const imageUrl = cmsUtils.payload(item, 'ImageUrl');
  const title = cmsUtils.payload(item, 'Title');
  //const desc = cmsUtils.payload(item, 'Desc');
  const bottomText = cmsUtils.payload(item, 'BottomText');
  //const ratingStar = cmsUtils.payload(item, 'RatingStar');
  //const alt = cmsUtils.payload(item, 'Alt');

  let itemElt = (<div>{item.itemTypeCode} {title} {bottomText}</div>);
  
  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <div className="testimonial-item">
      {itemElt}
      {subItemsElts}
    </div>
  );
}

