import React, { useEffect } from 'react';
/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from '../../utils';
import style from './styleMatchStyles';
import { getStyleMatchModel } from './styleMatchFormModel';
import { useForm } from '../../form';
//import env from '../../env';

//import { StyleMatchTest } from './styleMatchTest';
import data from './styleData';
import { useRouter } from '../../components';
import { NarrowLeft, NarrowRight } from '../../icons';

export function StyleMatch(props) {
  const { history } = useRouter();
  const navEl = React.useRef(null);
  //const [inTransition, setInTransition] = React.useState(false);
  const [lastStep, setLastStep] = React.useState(0);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [selection, setSelection] = React.useState(['', '', '', '', '', '', '']);
  const [submitTrigger, setSubmitTrigger] = React.useState(0);
  const model = getStyleMatchModel({});
  const form = useForm(model, { usePlaceholder: true });

  const onSelected = (questionIndex, answerId) => {
    //console.log(questionIndex, answerId, selection);

    selection[questionIndex] = answerId;
    setSelection(selection);
    moveNext();
  }

  const onSubmit = (e) => {
    e.preventDefault();

    form.validateForm(e, () => {
      //const value = { ...form.getValue() };
      setSubmitTrigger(submitTrigger + 1);
    });

    // const result = sum(selection);
    // const maxScore = Math.max(...result)
    // const targets = result.map((x, i) => x === maxScore ? i : '').filter(String);
    // const resultIndex = targets[Math.floor(Math.random() * targets.length)];
    // console.log(targets, resultIndex)
    // const page = data.results[resultIndex].page;
    // console.log(selection, result, maxScore, targets, resultIndex, page);
    // history.push(page);
  }

  const onMove = (loc) => {
    if (loc === 'prev')
      movePrev()
    else
      moveNext()
  }

  const moveNext = () => {
    moveTo(currentStep < selection.length ? currentStep + 1 : currentStep)
  }

  const movePrev = () => {
    moveTo(currentStep > 0 ? currentStep - 1 : 0)
  }

  const jump = (index) => {
    if (!clickable(index)) return
    moveTo(index)
  }

  const moveTo = (index) => {
    setLastStep(currentStep)
    setCurrentStep(index)
    if (!utils.ui.isElementInViewport(navEl.current)) {
      utils.ui.scrollTo(navEl.current)
    }
  }

  function isSelected(questionIndex, answerId) {
    return selection[questionIndex] === answerId;
  }

  function Step({ question, index }) {
    const locStyle = index === currentStep ? style.stepCurrent : (
      index === currentStep - 1 ? style.stepPrev : (
        index === currentStep + 1 ? style.stepNext : style.step))
    const movingStyle = index === lastStep ? style.stepLast : null;
    return <div
      css={[locStyle, movingStyle]}>
      <h4>STEP {index + 1}</h4>
      <h3>{question.title}</h3>
      <div css={style.tiles}>
        {question.answers.map(x => <div key={x.id}
          css={[style.tile, isSelected(index, x.id) && style.tileSelected]}
          onClick={() => onSelected(index, x.id)}>
          <div className="tileImage" css={style.tileImage}>
            <img src={utils.site.resourcePath(`/sites/vssassoon/media/style-match/${x.icon}`)} alt={x.label} />
          </div>
          <div css={{ marginTop: '0.3rem' }}>{x.label}</div>
          <div>{x.subLabel}</div>
        </div>)}
      </div>
    </div>
  }

  //

  // function LastStep({form}) {
  //   const locStyle = 7 === currentStep ? style.stepCurrent : (
  //     7 === currentStep + 1 ? style.stepNext : style.step)

  //   const render = (name) => form.renderControl(name, null);

  //   return <div className={`styleMatch__step`}
  //     css={locStyle}>
  //     <p>&nbsp;</p>
  //     <h2><strong>ALL DONE!</strong></h2>
  //     <p>Now submit your answers to view your style match.</p>
  //     <p>&nbsp;</p>

  //     <form className="">
  //       <div className="form-row style__match__email">
  //         <div className="col-md-6 mb-2">{render('name')}</div>
  //         <div className="col-md-6 mb-2">{render('email')}</div>
  //       </div>
  //       <div className="form-row unsubscribed">
  //         <div className="col-md-12 mb-2">{render('unsubscribed')}</div>
  //       </div>
  //       <h5><a className="button" href="/" onClick={onSubmit}><span><strong>SUBMIT</strong></span></a></h5>
  //     </form>

  //     <p>&nbsp;</p>
  //     <h5>We know your style changes, you can come back and update your choices whenever needed.&nbsp;</h5>
  //   </div>
  // }

  function Nav() {
    return <div css={style.navs}>
      {[0, 1, 2, 3, 4, 5, 6, 7].map((question, index) =>
        <div key={index} index={index}
          css={[style.nav,
          hasValue(index) && style.navFilled,
          index === currentStep ? style.navCurrent : null,
          clickable(index) && style.navClickable
          ]}
          onClick={() => jump(index)}
        ></div>)}
    </div>
  }

  function hasValue(index) {
    return !!selection[index]
  }

  function clickable(index) {
    if (hasValue(index)) return true;
    const maxIndexFilled = Math.max(...selection.map((x, i) => !!x ? i : -1));
    return index <= maxIndexFilled + 1
  }

  function canGoNext() {
    return currentStep < selection.length && clickable(currentStep + 1)
  }

  function canGoPrev() {
    return currentStep > 0 && clickable(currentStep - 1)
  }

  useEffect(() => {
    if (submitTrigger === 0) {
      return
    }

    const abortController = new AbortController();
    //const signal = abortController.signal;

    //const value = { ...form.getValue() };
    const result = sum(selection);
    const maxScore = Math.max(...result)
    const targets = result.map((x, i) => x === maxScore ? i : '').filter(String);
    const resultIndex = targets[Math.floor(Math.random() * targets.length)];
    //console.log(targets, resultIndex)
    const page = data.results[resultIndex].page;
    //const label = data.results[resultIndex].label;
    //console.log(selection, result, maxScore, targets, resultIndex, page);

    // console.log("selection", selection);
    // console.log("value", value);

    // const _questions = data.questions.map(x => {
    //   let _answer = '';
    //   let _checkBreak = false;
    //   for (let n in selection) {
    //     for (let nn in x.answers) {
    //       if (x.answers[nn].id === selection[n]) {
    //         _answer = x.answers[nn].label;
    //         _checkBreak = true;
    //         break;
    //       }
    //     }

    //     if (_checkBreak === true) {
    //       break;
    //     }

    //   }
    //   return { title: x.title, answer: _answer }
    // }
    // );

    const fetchData = async () => {

      // const res = await fetch(env.apiBase + "/api/stylematch/register", {
      //   method: 'post',
      //   signal: signal,
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({
      //     email: value.email,
      //     name: value.name,
      //     unsubscribed: value.unsubscribed,
      //     questions: _questions,
      //     label : label
      //   })
      // });

      //const data = await res.json();
      //console.log();
      history.push(page);
    };

    fetchData();
    return function cleanup() {
      abortController.abort();
    }

    // eslint-disable-next-line
  }, [submitTrigger]);

  return <React.Fragment>
    <div className="styleMatch" css={style.styleMatch}>
      {canGoPrev() && <Arrow loc="prev" currentStep={currentStep} onMove={onMove} />}
      <div className="styleMatch__steps" css={style.steps}>

        <div ref={navEl}><Nav /></div>
        <div css={style.stepsInner} style={{ transform: `translateX(${-100 * currentStep}%)` }}>
          {data.questions.map((question, inx) =>
            <Step key={inx} question={question} index={inx} />)}
          <LastStep form={form} currentStep={currentStep} onSubmit={onSubmit} />
        </div>
      </div>
      {canGoNext() && <Arrow loc="next" currentStep={currentStep} onMove={onMove} />}
    </div>
  </React.Fragment>

  //return <div>test <StyleMatchTest/> </div>
}

function Arrow({ loc, onMove }) {
  return <div
    css={[style.arrow, (loc === 'prev' ? style.arrowPrev : style.arrowNext)]} onClick={() => onMove(loc)}>
    {loc === 'prev' ? <NarrowLeft /> : <NarrowRight />}
  </div>
}

function sum([...choices]) {
  let results = [0, 0, 0, 0, 0, 0, 0, 0]
  choices.forEach(c => {
    const match = data.matches.find(x => x.answer === c);
    results = results.map(function (num, idx) {
      return num + match.results[idx];
    });
  })
  return results;
}

function LastStep({ form, currentStep, onSubmit }) {

  const locStyle = 7 === currentStep ? style.stepCurrent : (
    7 === currentStep + 1 ? style.stepNext : style.step)

  const render = (name) => form.renderControl(name, null);

  return <div className={`styleMatch__step`}
    css={locStyle}>
    <p>&nbsp;</p>
    <h2><strong>ALL DONE!</strong></h2>
    <p>Now submit your answers to view your style match.</p>
    <p>&nbsp;</p>

    <form className="">
      <div className="form-row style__match__email">
        <div className="col-md-6 mb-2">{render('name')}</div>
        <div className="col-md-6 mb-2">{render('email')}</div>
      </div>
      <div className="form-row unsubscribed">
        <div className="col-md-12 mb-2">{render('unsubscribed')}</div>
      </div>
      <h5><a className="button" href="/" onClick={onSubmit}><span><strong>SUBMIT</strong></span></a></h5>
    </form>

    <p>&nbsp;</p>
    <h5>We know your style changes, you can come back and update your choices whenever needed.&nbsp;</h5>
  </div>
}