import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { ProductList } from '../../../site/product/productList'
import { WhereToBuyList } from '../../../site/wheretobuy/wheretobuyList';
import { SearchResult } from '../../../site/searchResult/searchResult';
import { StyleMatch } from '../../../site/styleMatch/styleMatch';
// import { InstagramFeed } from '../../../components';
import { InstagramFeedEmbed } from '../../../components/InstagramFeedEmbed/InstagramFeedEmbed';
import { TextWithJavScript } from '../../../components/TextWithJavaScript/view';
import { AppVersion } from '../../../components';
import { WarrantyLazy } from '../../../site/warranty/warrantyLazy';
import { Join } from '../../../site/joinus/joinForm';
import { AmbassadorForm } from '../../../site/ambassador/ambassadorForm';

export function Code(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  const code = cmsUtils.payload(item, 'Code');
  const codeType = cmsUtils.payload(item, 'CodeType');
  let controls = null;
  if (codeType === 'Json') {

    const codeJson = JSON.parse(code);
    //codeJson.subCategory = "full-size";
    const searchType = (codeJson.category && 'category') || (codeJson.style && 'style') || (codeJson.subCategory && 'subCategory') || '';

    if (codeJson.control === 'product-list') {
      controls = <ProductList category={codeJson.category} style={codeJson.style} searchType={searchType} subCategory={codeJson.subCategory} />
    } else if (codeJson.control === 'where-to-buy-list') {
      controls = <WhereToBuyList />
    } else if (codeJson.control === 'search-results') {
      controls = <SearchResult />
    } else if (codeJson.control === 'style-match') {
      controls = <StyleMatch />
    } else if (codeJson.control === 'app') {
      controls = <AppVersion />
    } else if (codeJson.control === 'instagram') {
      controls = <InstagramFeedEmbed />
    } else if (codeJson.control === 'warranty') {
      controls = <WarrantyLazy />
    } else if (codeJson.control === 'join') {
      controls = <Join />
    } else if (codeJson.control === 'ambassador') {
      controls = <AmbassadorForm />
    }

  }

  const cssClass = utils.classNames('cms_item', item.cssClass || item.anchorName || '');

  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  if (cmsOption.isCmsEdit)
    return (
      <div className={cssClass}
        data-cms-item-id={item.itemId}
        id={item.anchorName}>
        <pre style={{ fontSize: '12px', margin: '5px', padding: '10px', background: '#eee', border: '1px solid #ccc' }}>
          <b>Code Type: {codeType}</b>
          {code} </pre>
      </div>
    )
  else if (codeType === 'Text')
    return (
      <div className={cssClass} id={item.anchorName}
        dangerouslySetInnerHTML={{ __html: code }}></div>
    )
  else if (codeType === 'TextWithJs')
    return (
      <TextWithJavScript item={item} cssClass={cssClass} />
    )
  else
    return (
      <div className={cssClass} id={item.anchorName}>{controls}</div>
    )
}
