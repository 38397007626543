import React, { Fragment } from "react";
import env from "../../env";
import { usePost } from "../../components";
import { ErrorPopup, Loading } from "../../components";
import { FilterDropdown } from "./filterDropdown";
import * as sortHelper from "./sortHelper";
import { Tile } from "./productTile";
import { gtm } from "../../lib/tracking";
import { useBV } from "../bazzarVoice/useBV";
import { Helmet } from "react-helmet-async";
import utils from "../../utils";

export function ProductList(props) {
  const post = usePost();
  const [filters, setFilters] = React.useState([]);
  const [filtersHC, setFiltersHC] = React.useState([]);
  const [filtersHT, setFiltersHT] = React.useState([]);
  const [filtersPT, setFiltersPT] = React.useState([]);
  const [filtersSR, setFiltersSR] = React.useState([]);

  const subCategory = props.subCategory;

  const [sortBy, setSortBy] = React.useState("price-desc");
  const [showAll, setShowAll] = React.useState(false);
  useBV(null, { showReview: null });
  const searchParam = { ...props };

  const noFilterPages = [
    "/style-bar/volume",
    "/style-bar/straight",
    "/style-bar/curls-and-waves",
    "/style-bar/length",
    "/style-bar/occasion",
  ];
  const noFilters = noFilterPages.includes(window.location.pathname);

  // console.log("searchParam.subCategory", searchParam.subCategory);

  // const categoryT =
  //   searchParam.category === "DRYERS" ||
  //   searchParam.category === "STRAIGHTENERS" ||
  //   searchParam.category === "CURLERS" ||
  //   searchParam.subCategory === "travel" ||
  //   searchParam.subCategory === "attachments" ||
  //   searchParam.subCategory === "diffusers" ||
  //   searchParam.subCategory === "straightening-irons" ||
  //   searchParam.subCategory === "straightening-brushes" ||
  //   searchParam.subCategory === "mini-hair-straighteners"
  //     ? ""
  //     : "none_dryers";

  const categoryT = "";

  React.useEffect(() => {
    post.send(env.apiBase + "/api/product/getProducts", searchParam);
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (
      post.response &&
      post.response.results &&
      post.response.results.searchResult &&
      filters.length > 0
    ) {
      const data = post.response.results.searchResult;
      const distinctFilters = getDistinctFilters(data.filters);
      const value = filters
        .map((x) => getFilterTitle(distinctFilters, x))
        .filter((x) => x)
        .join(",");
      //console.log(distinctFilters, filters, value)
      //console.log('sdfsdfsdfsd', value)
      //gtm.sendGAEvent('Product Filter', 'Filter By', value);
      gtm.sendEvent("productFilterPush", { productFilterName: value });
    }

    // eslint-disable-next-line
  }, [filters]);

  if (post.loading()) {
    return <Loading />;
  } else if (post.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={post.errors} />;
  }

  //console.log(post)
  const data = post.response.results.searchResult;

  const distinctFilters = getDistinctFilters(data.filters);
  const distinctFiltersHC = getDistinctFilters(data.filtersHC);
  const distinctFiltersHT = getDistinctFilters(data.filtersHT);
  const distinctFiltersPT = getDistinctFilters(data.filtersPT);
  const distinctFiltersSR = getDistinctFilters(data.filtersSR);

  const filterItems = [...distinctFilters];
  const filterItemsHC = [...distinctFiltersHC];
  const filterItemsHT = [...distinctFiltersHT];
  const filterItemsPT = [...distinctFiltersPT];
  const filterItemsSR = [...distinctFiltersSR];

  const sortItems = sortHelper.sortSelections();

  //origin
  //let filtered = filters.length > 0 ? getFilteredItems(data.items, filters, filterItemsHC, filterItemsHT) : data.items;

  //let filtered = getFilteredItems(data.items, filters, );
  let filtered = getFilteredItems(
    data.items,
    filters,
    filtersHC,
    filtersHT,
    filtersPT,
    filtersSR
  );

  sortHelper.sort(filtered, sortBy);

  const maxListing = utils.css.screenWidth() > 1600 ? 100 : 120;
  const showMoreButton =
    !showAll && filters.length > 0 && filtered.length > maxListing;
  //console.log(maxListing, !filter, filtered.length, maxListing,  showMoreButton)
  if (showMoreButton) {
    filtered = filtered.splice(0, maxListing);
  }

  const baseUrl = utils.site.isNZ
    ? "https://www.vssassoon.co.nz"
    : "https://www.vssassoon.com.au";
  const itemListStructuredData = {
    "@context": "https://schema.org/",
    "@type": "ItemList",
    "itemListElement": data.items.map((product, index) => ({
      "@type": "ListItem",
      "position": index + 1,
      "url": `${baseUrl}${product.productUrl}`,
      "image": product.productThumbnail,
      "name": product.productName,
    })),
  };

  if (data.totalCnt === 0) return <div>No product found.</div>;
  return (
    <>
      {data.items && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(itemListStructuredData)}
          </script>
        </Helmet>
      )}
      <div className={`prdFilterSort ${categoryT}`}>
        <div className='prdFilter'>
          {!subCategory && (
            <Fragment>
              {categoryT !== "none_dryers" && (
                <div className='filter__by'> FILTER BY </div>
              )}

              <div className='prdFilter_item'>
                <FilterDropdown
                  title={`${
                    categoryT !== "none_dryers"
                      ? "PRODUCT FEATURE*"
                      : "filter by*"
                  }`}
                  isMulti={true}
                  items={filterItems}
                  value={filters}
                  onChange={setFilters}
                  clearText='Clear filters'
                />
              </div>

              {categoryT !== "none_dryers" &&
                (searchParam.category === "CURLERS" ||
                  searchParam.category === "STYLERS" ||
                  searchParam.subCategory === "air-curlers") && (
                  <div className='prdFilter_item'>
                    <FilterDropdown
                      title='PRODUCT TYPE*'
                      isMulti={true}
                      items={filterItemsPT}
                      value={filtersPT}
                      onChange={setFiltersPT}
                      clearText='Clear filters'
                    />
                  </div>
                )}

              {categoryT !== "none_dryers" && (
                <div className='prdFilter_item'>
                  <FilterDropdown
                    title='HAIR CONCERN*'
                    isMulti={true}
                    items={filterItemsHC}
                    value={filtersHC}
                    onChange={setFiltersHC}
                    clearText='Clear filters'
                  />
                </div>
              )}

              {categoryT !== "none_dryers" && (
                <div className='prdFilter_item'>
                  <FilterDropdown
                    title='HAIR TYPE*'
                    isMulti={true}
                    items={filterItemsHT}
                    value={filtersHT}
                    onChange={setFiltersHT}
                    clearText='Clear filters'
                  />
                </div>
              )}

              {categoryT !== "none_dryers" &&
                (searchParam.category === "CURLERS" ||
                  searchParam.category === "STYLERS" ||
                  searchParam.subCategory === "automatic-curler" ||
                  searchParam.subCategory === "cordless" ||
                  searchParam.subCategory === "curling-irons" ||
                  searchParam.subCategory === "curling-wands" ||
                  searchParam.subCategory === "rollers" ||
                  searchParam.subCategory === "wavers" ||
                  searchParam.subCategory === "air-curlers" ||
                  searchParam.subCategory === "hot-air-stylers") && (
                  <div className='prdFilter_item'>
                    <FilterDropdown
                      title='STYLE RESULT*'
                      isMulti={true}
                      items={filterItemsSR}
                      value={filtersSR}
                      onChange={setFiltersSR}
                      clearText='Clear filters'
                    />
                  </div>
                )}
            </Fragment>
          )}
        </div>

        <div className='productSort'>
          {categoryT !== "none_dryers" && (
            <div className='filter__by' style={{ marginTop: "0.5rem" }}>
              SORT
            </div>
          )}

          <FilterDropdown
            items={sortItems}
            value={sortBy}
            onChange={setSortBy}
            rightAligned={true}
          />
        </div>
      </div>

      <div className='productList__warpper'>
        {!noFilters && (
          <div className={`${categoryT} p__filter__warpper`}>
            <div className='filter__by'> FILTER BY </div>

            {searchParam.subCategory !== "rollers" && (
              <GetRightFilter
                filterTitle='PRODUCT FEATURE'
                filterId='filters'
                items={filterItems}
                value={filters}
                onChange={setFilters}
              />
            )}

            {(searchParam.category === "CURLERS" ||
              searchParam.category === "STYLERS" ||
              searchParam.subCategory === "air-curlers") && (
              <GetRightFilter
                filterTitle='PRODUCT TYPE'
                filterId='filtersPT'
                items={filterItemsPT}
                value={filtersPT}
                onChange={setFiltersPT}
              />
            )}

            <GetRightFilter
              filterTitle='HAIR CONCERN'
              filterId='filtersHC'
              items={filterItemsHC}
              value={filtersHC}
              onChange={setFiltersHC}
            />

            <GetRightFilter
              filterTitle='HAIR TYPE'
              filterId='filtersHT'
              items={filterItemsHT}
              value={filtersHT}
              onChange={setFiltersHT}
            />

            {(searchParam.category === "CURLERS" ||
              searchParam.category === "STYLERS" ||
              searchParam.subCategory === "automatic-curler" ||
              searchParam.subCategory === "cordless" ||
              searchParam.subCategory === "curling-irons" ||
              searchParam.subCategory === "curling-wands" ||
              searchParam.subCategory === "rollers" ||
              searchParam.subCategory === "wavers" ||
              searchParam.subCategory === "air-curlers" ||
              searchParam.subCategory === "hot-air-stylers") && (
              <GetRightFilter
                filterTitle='STYLE RESULT'
                filterId='filtersSR'
                items={filterItemsSR}
                value={filtersSR}
                onChange={setFiltersSR}
              />
            )}

            <div className='filter__by' style={{ marginBottom: "0.3rem" }}>
              SORT
            </div>
            <GetRightSort
              items={sortItems}
              value={sortBy}
              onChange={setSortBy}
            />
          </div>
        )}

        <div className={`productList__container ${categoryT}`}>
          <div className={`productList ${categoryT}`}>
            {filtered.length === 0 ? (
              <div className='no_products'>
                Oops! Looks like there’s no product that matches this criteria.
                <br />
                Keep filtering, the perfect match awaits!
              </div>
            ) : (
              filtered.map((product, index) => (
                <Tile product={product} key={product.productCode}></Tile>
              ))
            )}
          </div>
          <div
            style={{
              fontSize: "0.75rem",
              color: "#777",
              textAlign: "left",
              marginLeft: "10px",
            }}
          >
            *Results depend on individual hair type & styling habits. Always
            read the product instructions before use.
          </div>
        </div>
      </div>

      {showMoreButton && (
        <div style={{ textAlign: "center" }}>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              setShowAll(true);
            }}
          >
            Show All
          </button>
        </div>
      )}
      {/* <div style={{ fontSize: "0.75rem", color: "#777", textAlign: "left" }}>
        *Results depend on individual hair type & styling habits. Always read
        the product instructions before use.
      </div> */}
    </>
  );
}

function getFilteredItems(
  items,
  filters,
  filtersHC,
  filtersHT,
  filtersPT,
  filtersSR
) {
  let _items = items;

  if (filters.length > 0) {
    _items = _items.filter((x) =>
      filters.every((filter) =>
        x.filterCodes.some((fc) => {
          return filter.indexOf(fc) >= 0;
        })
      )
    );
  }

  if (filtersPT.length > 0) {
    _items = _items.filter((x) =>
      filtersPT.every((filter) =>
        x.filterCodes.some((fc) => {
          return filter.indexOf(fc) >= 0;
        })
      )
    );
  }

  if (filtersHC.length > 0) {
    _items = _items.filter((x) =>
      filtersHC.every((filter) =>
        x.filterCodes.some((fc) => {
          return filter.indexOf(fc) >= 0;
        })
      )
    );
  }

  if (filtersHT.length > 0) {
    _items = _items.filter((x) =>
      filtersHT.every((filter) =>
        x.filterCodes.some((fc) => {
          return filter.indexOf(fc) >= 0;
        })
      )
    );
  }

  if (filtersSR.length > 0) {
    _items = _items.filter((x) =>
      filtersSR.every((filter) =>
        x.filterCodes.some((fc) => {
          return filter.indexOf(fc) >= 0;
        })
      )
    );
  }

  return _items;

  // Origin
  // return items.filter(x => filters.every(filter => x.filterCodes.some(fc => {
  //   //console.log(filter, fc, filter.indexOf(fc) >= 0)
  //   return filter.indexOf(fc) >= 0
  // })));

  //return items.filter(x => x.filterCodes.some(f => filters.every(t => t.indexOf(f) >= 0)))
}

// get distinct filter names (there could be the same named filter with different id in different categories.)
function getDistinctFilters(filters) {
  const distinctFilters = [];
  filters.forEach((x) => {
    const found = utils.array.find(
      distinctFilters,
      (f) => f.name === x.filterName
    );
    if (found) {
      found.value = found.value + `${x.filterCode}|`;
    } else {
      distinctFilters.push({
        label: `${x.filterName}`,
        value: `|${x.filterCode}|`,
      });
    }
  });
  return distinctFilters;
}

function getFilterTitle(distinctFilters, value) {
  const found = utils.array.find(distinctFilters, (f) => f.value === value);
  return found ? found.label : null;
}

function GetRightFilter({ filterTitle, filterId, items, value, onChange }) {
  const filterValues = value.toString();
  function handleChange(event) {
    const _value = event.target.value;
    onChange((pre) => {
      let _new = [...pre];
      const check = !filterValues.includes(_value);
      _new = _new.filter((item) => item !== _value);

      if (check === true) {
        _new.push(_value);
      }
      return _new;
    });
  }

  return (
    <div>
      <div className='filter__title'> {filterTitle} </div>
      <div className='form-group'>
        {items.map((item, index) => {
          return (
            <div key={index} className='form-check false'>
              <input
                className='form-check-input'
                id={`${filterId}_${index}`}
                type='checkbox'
                value={item.value}
                name={filterId}
                checked={filterValues.includes(item.value)}
                onChange={handleChange}
              />
              <label
                className='form-check-label'
                htmlFor={`${filterId}_${index}`}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
      <hr className='filter__line' />
    </div>
  );
}

function GetRightSort({ items, value, onChange }) {
  function handleChange(event) {
    const _value = event.target.value;
    onChange(_value);
  }

  return (
    <div>
      <div className='form-group'>
        {items.map((item, index) => {
          return (
            <div key={index} className='form-check false'>
              <input
                className='form-check-input'
                id={`rightSortInput_${index}`}
                type='radio'
                name='rightSortInput'
                value={item.value}
                checked={value === item.value && true}
                onChange={handleChange}
              />
              <label
                className='form-check-label'
                htmlFor={`rightSortInput_${index}`}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}
