import React, { useState, useEffect } from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";
import { NavBar } from "./navBar";
import { NavBarHori } from "./navBarHori";
import { FaAngleLeft } from "react-icons/fa";
import { useHistory } from "../../components";
import utils from "../../utils";

export function TopHeader(props) {
  const { pathname } = useRouter();
  const [menuOpened, setMenuOpened] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(pathname);
  const history = useHistory(pathname);
  const isMobile = utils.site.isMobile();

  const routeChanged = (path, history) => {
    if (typeof window !== `undefined`) {
      const hash = window.location.hash;
      const search = window.location.search;
      history.push(path + hash + search);

      if (hash) {
        scrollTo();
      }
    }
  };

  useEffect(() => {
    scrollTo();
  }, []);

  useEffect(() => {
    routeChanged(pathname, history);
    setCurrentLocation(pathname);
    // eslint-disable-next-line
  }, [pathname]);

  function scrollTo() {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const anchor = utils.ui.findElement(hash);
        if (anchor) utils.ui.scrollTo(anchor, 500);
      }, 500);
    }
  }

  function toggleMenu(e) {
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    setMenuOpened(false);
  }

  function goBack(e) {
    history.getPrevious();
  }

  function backTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
  if (isMobile) {
    return (
      <>
        <header className='topNav topNav2' id='top'>
          {history.history.length > 1 && (
            <SiteLink
              className='topNav__back'
              title='Go Back'
              to={history.getPreviousPath()}
              onClick={goBack}
            >
              <FaAngleLeft /> BACK
            </SiteLink>
          )}

          <div className='topNav__skewBar'></div>
          <SiteLink to='/' className='topNav__logo' onClick={backTop}>
            <img
              src='/assets/logos/logo.png'
              alt='logo'
              className='topNav__logoImg'
            />
            <img
              src='/assets/logos/logo_orange.png'
              alt='logo'
              className='topNav__logoImg--var1'
            />
            <img
              src='/assets/logos/logo_grey.png'
              alt='logo'
              className='topNav__logoImg--var2'
            />
            <img
              src='/assets/logos/logo_gold.png'
              alt='logo'
              className='topNav__logoImg--var3'
            />
          </SiteLink>

          <button
            id='mobile-menu-icon'
            type='button'
            aria-label='Menu'
            className={`hamburger hamburger--collapse ${
              menuOpened ? "is-active" : ""
            }`}
            onClick={toggleMenu}
          >
            <span className='hamburger-box'>
              <span className='hamburger-inner'></span>
            </span>
          </button>

          <NavBar
            open={menuOpened}
            onCloseMenu={onCloseMenu}
            currentLocation={currentLocation}
          ></NavBar>
        </header>
      </>
    );
  } else {
    return (
      <>
        <header className='topNav topNav2' id='top'>
          {history.history.length > 1 && (
            <SiteLink
              className='topNav__back'
              title='Go Back'
              to={history.getPreviousPath()}
              onClick={goBack}
            >
              <FaAngleLeft /> BACK
            </SiteLink>
          )}
          <div className='topNav__skewBar'></div>
          <SiteLink to='/' className='topNav__logo' onClick={backTop}>
            <img
              src='/assets/logos/logo.png'
              alt='logo'
              className='topNav__logoImg'
            />
            <img
              src='/assets/logos/logo_orange.png'
              alt='logo'
              className='topNav__logoImg--var1'
            />
            <img
              src='/assets/logos/logo_grey.png'
              alt='logo'
              className='topNav__logoImg--var2'
            />
            <img
              src='/assets/logos/logo_gold.png'
              alt='logo'
              className='topNav__logoImg--var3'
            />
          </SiteLink>
          <NavBarHori
            open={menuOpened}
            onCloseMenu={onCloseMenu}
            currentLocation={currentLocation}
          ></NavBarHori>
        </header>
      </>
    );
  }
}
