export function enableForceUpdate() {
  localStorage.setItem('sw-enableForceUpdate', 'true');
}

export function disableForceUpdate() {
  localStorage.setItem('sw-enableForceUpdate', 'false');
}

export function forceUpdateFlagged() {
  return localStorage.getItem('sw-enableForceUpdate') === 'true';
}

export function markUpdate() {
  localStorage.setItem('sw-markUpdate', 'true');
}

export function unmarkUpdate() {
  localStorage.setItem('sw-markUpdate', 'false');
}

export function hasUpdate() {
  return localStorage.getItem('sw-markUpdate') === 'true';
}

export async function checkUpdate() {
  if (hasUpdate()){
    unmarkUpdate();
    const registration = await navigator.serviceWorker.getRegistration();
    console.log(registration);
    if (registration && registration.waiting) {
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  }
}

// export function storeRegistration(registration) {
//   window.swRegistration = registration
// }

// export function getRegistration() {
//   return window.swRegistration
// }

// export function resetRegistration() {
//   const registration = getRegistration();
//   disableForceUpdate();
//   console.log('reset', registration)
//   if (registration && registration.waiting) {
//     registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//   }
// }