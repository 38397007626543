/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './ambassadorStyle';
import React from 'react';
import env from '../../env';

import { usePost } from '../../components';
import { useForm } from '../../form';
import { Button, ErrorMessage } from '../../components';
import { getFormData } from './ambassadorFormData';

export function AmbassadorForm() {

  const model = getFormData({})
  const form = useForm(model, {});
  const post = usePost();
  const [sendingStatus, setSendingStatus] = React.useState(null);

  const onSubmit = (e) => {
    form.validateForm(e, () => {
      const value = { ...form.getValue() };
      post.send(env.apiBase + "/api/ambassador/register", value);
      setSendingStatus('pending')
    })
  }
  if (post.done() && sendingStatus === 'pending') {
    form.reset(model)
    setSendingStatus(null)
  }


  const render = (name) => form.renderControl(name, null);
  return <div css={style.form}>


    <form>
      <h4>ABOUT YOU!</h4>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('name')}
        </div>
        <div className="col-12 col-md-6">
          {render('email')}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('hairType')}
        </div>
        <div className="col-12 col-md-6">
          {render('hairLength')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {render('hairConcerns')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {render('favouriteProduct')}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {render('reason')}
        </div>
      </div>

      <h4 style={{ marginTop: '2rem' }}>SOCIAL MEDIA</h4>
      <div className="row">
        <div className="col-12">
          {render('website')}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('websiteVisitor')}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          {render('instagram')}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('instagramAuFollowers')}
        </div>
        <div className="col-12 col-md-6">
          {render('instagramNzFollowers')}
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-12">
          {render('facebook')}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('facebookAuFollowers')}
        </div>
        <div className="col-12 col-md-6">
          {render('facebookNzFollowers')}
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-12">
          {render('youtube')}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('youtubeAuFollowers')}
        </div>
        <div className="col-12 col-md-6">
          {render('youtubeNzFollowers')}
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-12">
          {render('tiktok')}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          {render('tiktokAuFollowers')}
        </div>
        <div className="col-12 col-md-6">
          {render('tiktokNzFollowers')}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6">
          {render('unsubscribed')}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form__actions">
            <Button onClick={onSubmit} status={form.status}>Submit</Button>
            <ErrorMessage errors={form.errors} summaryMessage="Please review the errors." />
            <ErrorMessage errors={post.errors} />
            {post.done() && <span style={{ marginLeft: '1.5rem', color: 'red', fontWeight: 'bold' }}>Successfully sent!</span>}
          </div>
        </div>
      </div>

    </form>
  </div>;
}
