import utils from "../utils";
import env from '../env';

export const isMobile = () => {
  var width = utils.css.screenWidth()
  return width < 768
}

export function resourcePath(url) {
  return utils.url.isExternalLink(url) ? url : env.resourceBase + url;
}

export function fullUrl(url) {
  return utils.url.isExternalLink(url) ? url : env.baseOrigin + url;
}

export function getAlternates(path) {
  const alternates = [];

  alternates.push({ hreflang: "en-au", href: "https://www.vssassoon.com.au" + path })
  alternates.push({ hreflang: "en-nz", href: "https://www.vssassoon.co.nz" + path })
  alternates.push({ hreflang: "x-default", href: "https://www.vssassoon.com.au" + path })

  return alternates;
}

export function callMCPoopu() {
  const existingScript = document.getElementById('mcjs');
  if (!existingScript) {
    // if (env.country == "au") { 
    // }
    const script = document.createElement('script');
    script.type = `text/javascript`;
    script.text = `!function(c,h,i,m,p){m=c.createElement(h),p=c.getElementsByTagName(h)[0],m.async=1,m.src=i,p.parentNode.insertBefore(m,p)}(document,"script","https://chimpstatic.com/mcjs-connected/js/users/6c684a43947babf266fc13d82/719754c1d34fe6e8c82c8784b.js");`;
    script.id = 'mcjs';
    document.head.appendChild(script);
  }
}

export const isNZ = env.locale === 'nz'