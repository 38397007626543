import { css, keyframes  } from '@emotion/core'
import { variables, mq } from '../../cssInJs'
const spin = keyframes`
100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); }
`

const step = css(mq({
  flex: '0 0 100%',
  opacity: 0,
  maxHeight: '300px',
  transition: 'all 0.3s',
  '>div': {
    paddingLeft: [5, 5, 100],
    paddingRight: [5, 5, 100],
  }
}))

const navs = {
  navs: css(mq({
    marginTop: [5, 10, 20],
    marginBottom: 15,
    display: 'flex',
    justifyContent: 'center',
  })),
  nav: css(mq({
    width: 20,
    height: 30,
    background: '#ccc',
    margin: 5,
    transform: 'skewX(-12deg)'
  })),
  navCurrent: css({
    backgroundColor: variables.primaryColor
  }),
  navFilled: css({
    backgroundColor: '#666'
  }),
  navClickable: css({
    cursor: 'pointer'
  })
}

const tileSelected = css({
  '.tileImage:after': {
    opacity: '1',
    background: 'linear-gradient(0deg, rgba(194, 15, 47, 0.2) 0%, rgba(194, 15, 47, 1) 100%)',
    animation: `${spin} 6s linear infinite`,
  }
})
const tiles = {
  tiles: css(mq({
    marginLeft: [0, -5, null, -10],
    marginRight: [0, -5, null, -10],
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  })),
  tile: css(mq({
    flexBasis: ['50%', null, '33.33%'],
    padding: [0, 5, null, 10],
    cursor: 'pointer',
    maxWidth: [200, 200, 220],
    ':hover, :focus': tileSelected
  })),
  tileSelected: tileSelected,
  tileImage: css({
    position: 'relative',
    padding: '10px',

    ':after': {
      content: `''`,
      position: 'absolute',
      left: '0',
      top: '0',
      zIndex: '-1',
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      transition: 'all 1s',
      opacity: '0',
      background: 'rgb(255, 255, 255)',
    }
  })
}

export default {
  styleMatch: css({
    position: 'relative',
    maxWidth: '1300px',
    margin: 'auto',
    paddingBottom: '3rem'
  }),

  steps: css({
    overflow: 'hidden',
  }),
  stepsInner: css({
    display: 'flex',
    transition: 'all 1s cubic-bezier(0.22, 0.61, 0.36, 1)',
    '& h3, & h4': {
      textTransform: 'uppercase'
    }
  }),
  step,
  stepCurrent: css(step, {
    maxHeight: '2000px',
    opacity: 1
  }),
  stepPrev: css(step, {}),
  stepNext: css(step, {}),
  stepLast: css({
    //maxHeight: '1000px',
    opacity: 1
  }),

  arrow: css(mq({
    position: "absolute",
    cursor: 'pointer',
    color: '#aaa',
    transition: 'all 0.3s',
    zIndex: '1',
    // xs, sm(>=576), md(>=768), lg(>=992), xl(>=1200), xxl(>=1600)
    top: 0,
    // [mqMax.md]: {
    //   width: 100
    // },
    width: [20, 25, 30, 40],
    '&:hover': {
      color: '#666'
    }
  })),
  arrowPrev: css(mq({
    left: [-15, 0]
  })),
  arrowNext: css(mq({
    right: [-15, 0]
  })),

  ...tiles,
  ...navs
}
