const questions = [
  {
    title: `What's your natural hair type`,
    answers: [
      { id: 'B', label: 'Straight', icon: 'q1-straight.png' },
      { id: 'C', label: 'Wavy', icon: 'q1-wavy.png' },
      { id: 'D', label: 'Curly', icon: 'q1-curly.png' },
    ]
  },
  {
    title: `What's your natural hair texture`,
    answers: [
      { id: 'E', label: 'Fine', icon: 'q3-fine.png' },
      { id: 'F', label: 'Medium', icon: 'q3-medium.png' },
      { id: 'G', label: 'Thick', icon: 'q3-thick.png' },
    ]
  },
  {
    title: `What's your current hair length`,
    answers: [
      { id: 'H', label: 'Short', icon: 'q5-short-2.png' },
      { id: 'I', label: 'Medium', icon: 'q5-medium-2.png' },
      { id: 'J', label: 'Long', icon: 'q5-long-2.png' },
    ]
  },
  {
    title: 'What is your biggest hair concern',
    answers: [
      { id: 'K', label: 'Frizzy', icon: 'q4-frizzy.png' },
      { id: 'L', label: 'Dry & Dull', icon: 'q4-dry.png' },
      //{ id: 'L', label: 'Dull', icon: 'q4-dull.png' },
      { id: 'M', label: 'Fine', icon: 'q4-fine.png' },
      { id: 'N', label: 'Overly Treated / Colour Fade', icon: 'q4-coloured.png' },
    ]
  },
  {
    title: 'And, how do you tend to wear it',
    answers: [
      { id: 'O', label: 'Keep it consistent', icon: 'q5-consistent-2.png' },
      { id: 'P', label: 'Regularly change it up', icon: 'q2-change-it.png' },
      { id: 'Q', label: 'Try to be adventourous', icon: 'q5-adventurous-2.png' },
    ]
  },
  {
    title: 'Choose your most important hair goal',
    answers: [
      { id: 'R', label: 'Build Volume', icon: 'q6-volume-2.png' },
      { id: 'T', label: 'Tame Frizz', icon: 'q6-frizz-2.png' },
      { id: 'S', label: 'Create Curls', icon: 'q6-curls-2.png' },
      { id: 'U', label: 'Create Waves', icon: 'q6-waves.png' },
      { id: 'V', label: 'Straight Styles', icon: 'q6-straight-styles-2.png' },
      { id: 'W', label: 'Enhance Your Natural Hair', icon: 'q6-natural-2.png' },
      //{ id: 'W', label: 'Add texture', icon: 'q6-texture.png' },
      { id: 'X', label: 'Enhance Hair Health', icon: 'q6-hair-health.png' },
    ]
  },
  {
    title: 'Choose your style vibe',
    answers: [
      { id: 'Y', label: 'Casual', icon: 'q7-casual.png' },
      { id: 'Z', label: 'Classic', icon: 'q7-sleek.png' },//new
      { id: 'AA', label: 'Boss Babe', subLabel: '', icon: 'q7-luxe.png' },
      { id: 'AB', label: 'Edgy', subLabel: '', icon: 'q7-edgy.png' },
      { id: 'AC', label: 'Girly', subLabel: '', icon: 'q7-girly.png' },
      { id: 'AD', label: 'Socialite', subLabel: '', icon: 'q7-glam.png' },//new
      { id: 'AE', label: 'Boho', subLabel: '', icon: 'q7-boho-2.png' },
      { id: 'AF', label: 'Glam', subLabel: '', icon: 'q7-glam-2.png' },//??
    ]
  }
]

const results = [
  { id: '1', label: 'Natural Beauty', page: '/hair-style-quiz-natural-beauty' },
  { id: '2', label: 'Polished Elegance', page: '/hair-style-quiz-polished-elegance' },
  { id: '3', label: 'Sleek Style', page: '/hair-style-quiz-sleek-style' },
  { id: '4', label: 'Romantic at Heart', page: '/hair-style-quiz-romantic-at-heart' },
  { id: '5', label: 'Free Spirit', page: '/hair-style-quiz-free-spirit' },
  { id: '6', label: 'Bold Beauty', page: '/hair-style-quiz-bold-beauty' },
  { id: '7', label: 'Urban Chic', page: '/hair-style-quiz-urban-chic' },
  { id: '8', label: 'Party Gal', page: '/hair-style-quiz-party-gal ' },
]

const matches = [  
  { answer: 'B', results: [1, 1, 1, 1, 1, 1, 1, 1]}, 
{ answer: 'C', results: [1, 1, 1, 1, 1, 1, 1, 1]}, 
{ answer: 'D', results: [1, 1, 1, 1, 1, 0, 0, 0]}, 
{ answer: 'E', results: [1, 1, 1, 1, 1, 1, 1, 1]}, 
{ answer: 'F', results: [1, 1, 1, 1, 1, 1, 1, 1]}, 
{ answer: 'G', results: [1, 0, 1, 1, 1, 1, 0, 1]}, 
{ answer: 'H', results: [1, 0, 0, 0, 0, 1, 0, 0]}, 
{ answer: 'I', results: [1, 1, 1, 1, 1, 1, 1, 1]}, 
{ answer: 'J', results: [1, 1, 1, 1, 1, 1, 1, 1]}, 
{ answer: 'K', results: [1, 0, 1, 0, 0, 0, 1, 1]}, 
{ answer: 'L', results: [0, 0, 0, 0, 1, 1, 1, 0]}, 
{ answer: 'M', results: [1, 1, 1, 1, 1, 1, 0, 1]}, 
{ answer: 'N', results: [0, 0, 1, 0, 0, 1, 1, 0]}, 
{ answer: 'O', results: [1, 0, 0, 0, 0, 0, 0, 0]}, 
{ answer: 'P', results: [0, 1, 1, 1, 1, 1, 1, 0]}, 
{ answer: 'Q', results: [0, 0, 0, 0, 1, 0, 0, 1]}, 
{ answer: 'R', results: [0, 1, 1, 1, 0, 1, 0, 1]}, 
{ answer: 'S', results: [1, 0, 1, 0, 0, 0, 1, 1]}, 
{ answer: 'T', results: [0, 1, 0, 1, 1, 1, 0, 1]}, 
{ answer: 'U', results: [0, 0, 1, 1, 1, 1, 1, 1]}, 
{ answer: 'V', results: [1, 0, 1, 0, 1, 0, 0, 1]}, 
{ answer: 'W', results: [1, 0, 0, 0, 0, 0, 0, 0]}, 
{ answer: 'X', results: [1, 0, 1, 0, 0, 1, 0, 0]}, 
{ answer: 'Y', results: [1, 0, 0, 1, 1, 0, 0, 0]}, 
{ answer: 'Z', results: [1, 1, 1, 1, 0, 1, 0, 0]}, 
{ answer: 'AA', results: [0, 1, 1, 0, 0, 1, 0, 0]}, 
{ answer: 'AB', results: [0, 0, 0, 0, 1, 1, 1, 0]}, 
{ answer: 'AC', results: [0, 0, 0, 1, 1, 1, 1, 0]}, 
{ answer: 'AD', results: [0, 0, 0, 1, 1, 0, 1, 1]}, 
{ answer: 'AE', results: [0, 0, 0, 1, 0, 1, 0, 0]}, 
{ answer: 'AF', results: [0, 0, 1, 1, 0, 1, 1, 0]}, 
]

export default { questions: questions, results: results, matches: matches };


// =CONCATENATE("{ answer: '",B15,"', results: [", 
// "'", B5, "', ",
// "'", B6, "', ",
// "'", B7, "', ",
// "'", B8, "', ",
// "'", B9, "', ",
// "'", B10, "', ",
// "'", B11, "', ",
// "'", B12, "'",
// , "]}, ")