import React, { lazy, Suspense } from 'react';
import { Loading } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
const ShineLustre = lazy(() => import('./shineLustre'));

export function ShineLustreLoading(props){
    return(
        <div>
            <Suspense fallback={<Loading />}>
                <ShineLustre />
            </Suspense>
            <div className="shineLustreFooter">
                <ViewPartial/>
            </div>
        </div>
    )
}