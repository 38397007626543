/** @jsx jsx */
import { jsx } from "@emotion/core";
import style from "./digitalSensorLuxeStyle";

import "./scss/index.scss";

import { VideoPanel } from "./videoPanel";

export default function YoutubeTest() {
  //const popupImages = ["https://www.youtube.com/watch?v=Qw4BJHabHIk"];

  function openVideo(index) {}

  return (
    <div className="digitalSensorLuxeLanding" css={style.landingPage}>
      <div css={style.container}>
        <VideoPanel style={style} openVideo={openVideo} />
      </div>
    </div>
  );
}
