import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useRouter } from "../../components";
import { SiteLink } from "../../components";
import BottomDrawer from "../../components/bottomDrawer";
import { useStore } from "../../store";

export function BottomDock(props) {
  const links = [
    { url: "/products", title: "PRODUCTS" },
    { url: "/warranty", title: "WARRANTY" },
    { url: "/style-bar", title: "STYLE BAR" },
    { url: "/blog", title: "BLOG" },
    { url: "/contact", title: "CONTACT" },
  ];

  //const path = usePath();
  const { pathname } = useRouter();
  const { state, dispatch } = useStore();

  console.log("state.toggleBottomMenu", state.toggleBottomMenu);

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  };

  const linkElts = links.map((link, index) => (
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? "selected" : ""}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`}>
        <div className="icon"></div>
        <div>{link.title}</div>
      </SiteLink>
    </li>
  ));

  const bottomMenu = useRef(null);
  useLayoutEffect(() => {
    //console.log('useLayoutEffect');
    const slider = new BottomDrawer(bottomMenu.current);

    setTimeout(() => {
      slider.init();

      if (pathname === "/hydro-smooth" || pathname === "/digital-sensor-luxe" || pathname === "/digital-sensor-luxe-test") {
        slider.close();
      }
    }, 1000);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!state.toggleBottomMenu) {
      return;
    }
    const slider = new BottomDrawer(bottomMenu.current);
    setTimeout(() => {
      slider.init();
      slider.close();
    }, 1000);
    dispatch({ type: "SET_STATE", payload: { toggleBottomMenu: null } });

    // eslint-disable-next-line
  }, [state.toggleBottomMenu]);

  return (
    <>
      <nav className="bottomMenu" ref={bottomMenu}>
        <div className="bottomMenu__bar"></div>
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">{linkElts}</ul>
      </nav>
      <div className="bottomMenuPadding"></div>
    </>
  );
}
