/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { mixin, mq } from "./styles/common";

import utils from "utils";
import { YoutubeBannerMobilePopup } from "./youtubeBanner--mobilePopup";

const { px } = mixin;

export function VideoPanel({ style, openVideo }) {
  const ss = getSectionStyle(style);

  const video = {
    heightSet: "full",
    videoLinkOrId: "https://www.youtube.com/watch?v=Qw4BJHabHIk",
    playerId: "ytBanner-digitalSensorLuxe",
  };

  function testOption(opt) {
    window.localStorage.setItem("youtubeTestOpt", opt);
    window.location.reload();
  }

  const mobileImageUrl = "/dist/digitalSensorLuxe/new/video-poster-frame.jpg";
  const posterImageUrl = "/dist/digitalSensorLuxe/new/video-poster-frame.jpg";
  const canPlayVideo = () => utils.css.screenWidth() >= 1200;
  return (
    <section css={ss.section} className="youtubeVideoPanel_digitalSensor">
      <div css={ss.content}>
        <YoutubeBannerMobilePopup
          video={video}
          canPlayVideo={canPlayVideo()}
          mobileImageUrl={mobileImageUrl}
          posterImageUrl={posterImageUrl}
          sizeCalculator={getSize}
        />

        <div style={{ margin: "20px 0", background: "white", padding: "10px", fontSize: "14px" }}>
          <button onClick={() => testOption(1)}>1. Iframe-def</button>
          <button onClick={() => testOption(2)} style={{ marginLeft: "5px" }}>
            2. Js-auto
          </button>
        </div>

        <video autoPlay loop muted playsInline>
          <source src="/dist/test.mp4" type="video/mp4" />
        </video>
      </div>
    </section>
  );
}

function getSectionStyle(style) {
  const sectionStyle = {
    section: css(
      mq({
        backgroundColor: "black",
        paddingTop: [0],
        paddingBottom: [0],
      })
    ),
    content: css({
      marginBottom: px(0, 0),
    }),
  };
  return sectionStyle;
}

function getSize(containerSize, canPlayVideo) {
  if (window.innerWidth < 1200) return false;
  if (!canPlayVideo) return false;
  const overSize = 1;
  const videoRatio = 9 / 16;

  const bottomHeight = getBottomHeight();

  //min Height
  // if(window.innerWidth >= 2560 && containerSize.h <= 460){
  //   containerSize.h = 460;
  // }
  // else{
  //   containerSize.h -= bottomHeight;
  // }

  console.log("containerSize", containerSize);

  //document.querySelector(".youtubeVideoPanel_digitalSensor .youtubePlayer").style.height = containerSize.h + "px";
  document.querySelector(".youtubeVideoPanel_digitalSensor .youtubePlayer").style.height = "calc(100vh - " + bottomHeight + "px)";

  const containerRatio = containerSize.h / containerSize.w;
  const w = containerSize.w * overSize;
  const h = containerSize.h * overSize;
  const size = { width: w, height: w * videoRatio, left: 0, top: 0 };

  if (containerRatio < videoRatio) {
    // console.log("wide");
    // wide screen --> set height as base
    size.height = h;
    size.width = h / videoRatio;
  } else {
    // console.log("square");
    // square screen --> set width as base
    size.height = w * videoRatio;
    size.width = w;
  }

  // console.log(size, w, h, videoRatio, containerRatio);

  //console.log(size)
  size.left = -(size.width - containerSize.w) / 2;
  size.top = -(size.height - containerSize.h) / 2;

  return size;
}

function getBottomHeight() {
  try {
    return (
      // document.querySelector(".bottomMenu").offsetHeight +
      document.querySelector(".bottomMenu__bar").offsetHeight +
      document.querySelector(".topNav").offsetHeight +
      document.querySelector(".bottomMenuPadding").offsetHeight
    );
  } catch (e) {}
  return 100;
}
