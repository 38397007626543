import React from "react";
import { useRouter } from "../../components";

export function SearchBar2() {
  const [keyword, setKeyword] = React.useState("");
  const { navigate } = useRouter();
  function search(e) {
    e.preventDefault();
    navigate("/search-results?q=" + encodeURIComponent(keyword));
  }
  function onChange(e) {
    setKeyword(e.target.value);
  }
  return (
    <form className='searchBar searchBar2' onSubmit={(e) => search(e)}>
      <input
        type='text'
        className='searchBar__input searchBar__input2'
        placeholder='SEARCH'
        value={keyword}
        onChange={onChange}
      ></input>
    </form>
  );
}
