import { css  } from '@emotion/core'
export default {
  form: css({
    position: 'relative',
    margin: 'auto',
    marginBottom: '3rem',
    padding: '1rem 1rem 2rem',
    background: 'rgba(255,255,255,0.9)',
    textAlign: 'left',
    
    maxWidth: '960px',
  }),
}
