//import HtmlInput from 'lib/form/controls/HtmlInput--ckeditor';
import FilePondUploader from '../lib/form/controls/FilePond';

export function getSiteControl(control, formOption) {
  // if (control.type === 'html')
  //   return HtmlInput;
  if (control.type === 'filePond')
    return FilePondUploader;
  return null;
}

