import Hammer from '@egjs/hammerjs'
import { gsap } from 'gsap';

export default class BottomDrawer {
  constructor(el, options = {}) {
    const defaults = {};
    this.settings = Object.assign({}, defaults, options);
    this.slider = el;

    // location
    this.currentTranslateY = 0;
    this.size = null;
    this.status = 'expanded';
    this.inititialised = false;
    this.tsEventHandled = new Date();
  }

  init() {
    this.size = this.getSize(this.slider);
    //console.log(this.size)
    //this.close()
    this.open()

    //
    const hammer = new Hammer.Manager(this.slider);
    hammer.add(new Hammer.Pan({ direction: Hammer.DIRECTION_VERTICAL, threshold: 0 }));

    hammer.on('panstart pan panend', e => {
      //console.log(e.type, e)
      if (e.type === 'panend') {
        this.switch(e.deltaY);
      } else if (e.type === 'pan') {
        this.moveDeltaTmp(e.deltaY)
      }
    });

    this.initEvent();
  }

  initEvent() {
    const bar = this.slider.querySelector('.bottomMenu__bar');
    if (this.inititialised) {
      bar.removeEventListener('click', (e) => this.handleClick(e, this))
    }
    bar.addEventListener('click', (e) => this.handleClick(e, this))
    this.inititialised = true;
  }

  handleClick(e, self) {
    e.preventDefault();
    e.stopPropagation();
    const dt = (new Date()) - this.tsEventHandled;
    //console.log('handleClick', dt);

    if (dt < 100) return;
    self.switch();
  }

  moveDeltaTmp(delta){
    const y = this.getBaseY() + delta;
    //console.log(y,delta)

    this.currentTranslateY = y < 0? 0 : (y > this.size.max ? this.size.max : y);
    this.move(this.currentTranslateY);
  }

  switch(delta){
    this.tsEventHandled = new Date();
    if ((delta < 0 && this.status === 'expanded') || (delta > 0 && this.status === 'collapsed')) return;
    const targetY = this.getTargetY();
    //console.log('return', this.currentTranslateY, targetY)
    
    gsap.fromTo(this.slider, {y: this.currentTranslateY}, {duration: 0.3, y: targetY, ease: 'power1'});
    this.status = this.status === 'collapsed'? 'expanded' : 'collapsed';
    this.currentTranslateY = targetY;
  }

  getBaseY() {
    return this.status === 'collapsed'? this.size.max : this.size.min;
  }

  getTargetY() {
    return this.status === 'collapsed'? this.size.min : this.size.max;
  }

  getSize(el) {
    const links = el.querySelector('.bottomMenu__links');
    const bar = el.querySelector('.bottomMenu__bar');
    return { min: 0, max: links.offsetHeight, barHeight: bar.offsetHeight };
  }

  move(dy) {
    this.slider.style.transform = `translate3d(0, ${dy}px, 0)`;
  }

  close() {
    this.status = 'collapsed';
    this.currentTranslateY = this.size.max;
    this.move(this.currentTranslateY);
  }

  open() {
    this.status = 'expanded';
    this.currentTranslateY = this.size.min;
    this.move(this.currentTranslateY);
  }
}