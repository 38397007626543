import React, { lazy, Suspense } from 'react';
import { Loading } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
const Petite = lazy(() => import('./petite'));

export function PetiteLoading(props){
    return(
        <div>
            <Suspense fallback={<Loading />}>
                <Petite />
            </Suspense>
            <div className="petiteFooter">
                <ViewPartial/>
            </div>
        </div>
    )
}