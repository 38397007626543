import React, { lazy, Suspense } from 'react';
//import { BrillianceDefense } from './brilliance';
import { Loading } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
const Brilliance = lazy(() => import('./brilliance'));

export function BrillianceLoading(props) {
  return <div>
    <Suspense fallback={<Loading />}>
     <Brilliance />
    </Suspense>
    <div className="brillianceFooter">
      <ViewPartial pageOptions={{ imagePath: '/dist/brilliance/images/love-your-style-brilliance-banner.jpg' }}/>
    </div>
  </div>
}