import { css } from "@emotion/core";
//import { au } from '../../components';
import { variables, mixin, mqMin, mq, base } from "./styles/common";
//import { rgba } from 'polished';
//import utils from '../../utils';

const { rem, remM } = mixin;

const sc = {
  content: {
    position: "relative",
    zIndex: "1",
    fontSize: remM(16),
    textAlign: "left",
    //background: variables.color.pannelColor,
    color: variables.color.pannelColor,
    //padding: `25px 60px 500px 25px`,
    overflow: "hidden",
    [mqMin.small]: {
      fontSize: rem(20),
      fontWeight: 400,
      position: "absolute",
      zIndex: "12",
      left: "50%",
    },
  },
  header: css(base.h2, {
    color: variables.color.pannelColor,
    fontSize: remM(22),
    [mqMin.small]: {
      fontSize: rem(36),
    },
  }),
  subHeader: css(base.h3, {
    color: variables.color.pannelColor,
    fontSize: remM(18),
    marginBottom: rem(25),
    [mqMin.small]: {
      fontSize: rem(21),
    },
  }),
  button: css(base.button, {
    marginTop: 10,
    fontSize: remM(20),
    //padding: `${rem(4)}, ${rem(15)}`,
    [mqMin.small]: {
      marginTop: rem(10),
      fontSize: rem(28),
      letterSpacing: "1px",
    },
  }),
  product: css({
    pointerEvents: "none",
    position: "absolute",
    zIndex: 13,
    left: "50%",
  }),
  package: css({
    pointerEvents: "none",
    width: 200,
    [mqMin.small]: {
      width: rem(365),
      position: "absolute",
      //marginBottom: rem(20)
    },
  }),
};

const videoCommon = {
  videoContainer: css({
    position: "relative",
    marginBottom: rem(20),
  }),
  playIcon: css({
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    width: rem(100),
    height: rem(100),
    color: "white",
    pointerEvents: "none",
    cursor: "pointer",
  }),
};

const parallax = {
  panel: css(
    mq({
      height: [200, 570],
      backgroundPositionX: "center",
      backgroundRepeat: "no-repeat",
      backgroundImage: "url(/dist/unbound/images/Lifestyle_Vanity_HD.jpg)",
    })
  ),
};

export default {
  landingPage: css({
    // overflow: "hidden",
    //background: variables.color.bgColor,
    background: "black",
    fontFamily: "Gotham",
    fontSize: rem(20),
    fontWeight: 400,
    img: {
      width: "100%",
    },
  }),
  container: css({
    maxWidth: "2560px",
    overflow: "hidden",
    margin: "auto",
  }),
  container2: css({
    maxWidth: "2560px",
    overflow: "hidden",
    margin: "auto",
  }),
  notes: mq({
    fontSize: [remM(13), rem(15)],
  }),
  parallax,
  sc,
  videoCommon,
  base,
  variables: variables,
};
