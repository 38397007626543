import { SearchBar2 } from "layout/topHeader/searchBar2";
import React from "react";

export function NotFound(props) {
  const debugInfo = props.debugInfo;

  return (
    <div className='error-panel'>
      <div className='container'>
        <h1>Oops!</h1>
        <p>It looks like we couldn't find the page you're looking for!</p>
        <p>
          Try searching below, or head to our <a href='/'>homepage</a>.
        </p>
        <SearchBar2 />
        {debugInfo && (
          <pre
            style={{
              marginTop: "3rem",
              color: "chocolate",
              fontSize: "0.7rem",
            }}
          >
            Error message: {debugInfo.pageUrl} - {debugInfo.loadFrom}{" "}
          </pre>
        )}
      </div>
    </div>
  );
}
