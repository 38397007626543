/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect, Fragment } from "react";
import { Loading } from "../../components";
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function TiktokEmbedByLink({ className, url }) {
  const [code, setCode] = useState();
  const [openPopup, setOpenPopup] = useState(false);

  const s = getStyle();

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    fetch(`https://www.tiktok.com/oembed?url=${url}`, { signal: signal })
      .then((res) => res.json())
      .then((res) => {
        setCode(res);
      })
      .catch((error) => {
        setCode({ error: "Load error" });
      });

    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  function close() {
    setOpenPopup(false);
    document.body.style.overflow = "unset";
  }

  function open() {
    setOpenPopup(true);
    document.body.style.overflow = "hidden";
  }

  if (!code) {
    return (
      <div className={className}>
        <Loading />
      </div>
    );
  }
  
  return (
    <Fragment>
      {openPopup && (
        <Portal>
          <div css={s.portal__background} className="portal__background">
            <div
              className="portal__close__btn"
              css={s.portal__close__btn}
              onClick={close}
            >
              <MdClose />
            </div>
          </div>

          <div css={s.portal__contents} className="portal__contents">
            <TiktokItem styleItem={s} html={code.html} />
          </div>
        </Portal>
      )}

      {code.error ? (
        <div className={className} css={s.titok__embed__container__error}>
          <div
            className="titok__embed__watch__icon__text"
            css={s.titok__embed__watch__icon__text__error}
          >
            Load Error
            <div style={{ padding: "1rem", textAlign: "left" }}>{url}</div>
          </div>
        </div>
      ) : (
        <div
          className={className}
          css={s.titok__embed__container}
          onClick={open}
        >
          <img src={code.thumbnail_url} width="100%" alt={code.title} />
          <div
            className="titok__embed__watch__icon"
            css={s.titok__embed__watch__icon}
          >
            <div>
              <img src="/assets/watch.png" alt="watch icon" />
            </div>
            <div
              className="titok__embed__watch__icon__text"
              css={s.titok__embed__watch__icon__text}
            >
              Watch on Tiktok
            </div>
          </div>
        </div>
      )}
    </Fragment>
    // <div className={className} dangerouslySetInnerHTML={{ __html: template }}></div>
  );
}

function TiktokItem({ styleItem, html }) {
    
    const template = html;
    const [show, setShow] = useState(false);

  useEffect(() => {
    const existingScript = document.getElementById("tiktokEmbedScript");
    if (existingScript) {
      document.body.removeChild(existingScript);
    }

    const script = document.createElement("script");
    script.type = `text/javascript`;
    script.id = "tiktokEmbedScript";
    script.async = true;
    script.src = "https://www.tiktok.com/embed.js";

    document.body.appendChild(script);

    let vvv = null;
    const abc = setInterval(() => {
      console.log("check inverval tiktokEmbedScript");
      if (document.getElementById("tiktokEmbedScript")) {
        vvv = setTimeout(() => {
          setShow(true);
        }, 2000);
        clearInterval(abc);
      }
    }, 100);

    return function cleanup() {
      document.body.removeChild(script);
      clearInterval(abc);
      if (vvv) {
        clearTimeout(vvv);
      }
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      {!show && <Loading />}
      <div
        css={styleItem.tiktok__item__show}
        className={`${show && "show"}`}
        style={{ padding: "1rem" }}
        dangerouslySetInnerHTML={{ __html: template }}
      ></div>
    </Fragment>
  );
}

function getStyle() {
  const style = {
    titok__embed__container: {
      maxWidth: "324px",
      position: "relative",
      cursor: "pointer",
      "&:after": {
        content: '""',
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        //zIndex: 2,
        backgroundColor: "rgba(0,0,0,0.5)",
        position: "absolute",
      },
    },

    titok__embed__container__error: {
      width: "324px",
      position: "relative",
      cursor: "pointer",
      height: "300px",
      backgroundColor: "grey",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },

    titok__embed__watch__icon: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 2,
    },
    titok__embed__watch__icon__text: {
      fontSize: "14px",
      marginTop: "-1.5rem",
      fontWeight: 500,
      textAlign: "center",
      color: "white",
    },

    titok__embed__watch__icon__text__error: {
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "center",
      color: "white",
    },

    portal__background: {
      backgroundColor: "rgba(0,0,0,0.8)",
      position: "fixed",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      zIndex: "9998",
    },

    portal__close__btn: {
      position: "fixed",
      zIndex: "9999",
      right: 0,
      top: 0,
      margin: "1rem",
      cursor: "pointer",
      svg: {
        width: "30px",
        height: "30px",
        color: "white",
      },
    },

    portal__contents: {
      position: "fixed",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 9999,
      //width: "100%",
    },

    tiktok__item__show: {
      maxHeight: "0px",
      overflow: "hidden",
      "&.show": {
        maxHeight: "1000px",
        backgroundColor: "white",
      },
    },
  };
  return style;
}
