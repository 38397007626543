import React from "react";
import utils from "../../utils";
import { SiteLink } from "../../components";
//import env from '../../env';

export function Tile({ product }) {
  const imageUrl = product.productThumbnail
    ? utils.site.resourcePath(product.productThumbnail)
    : "/assets/product-empty.png";
  //console.log(imageUrl)
  const productUrl = product.productUrl
    ? product.productUrl
    : `/product/${product.productCode}`;
  //const barcodeCleaned = product.barcode ? product.barcode.replace(/-/g, '').replace(/ /g, '') : null;
  return (
    <div className='productTile tile'>
      {/* <div itemScope={true} itemType="http://schema.org/Product">
        <meta itemProp="name" content={product.productName} />
        <div itemProp="brand" itemType="http://schema.org/Brand" itemScope>
          <meta itemProp="name" content={env.siteName} />
        </div>

        {product.intro && <meta itemProp="description" content={product.intro} />}
        {barcodeCleaned && <meta itemProp="sku" content={barcodeCleaned} />}
        {barcodeCleaned && <meta itemProp="gtin13" content={barcodeCleaned} />}
        {imageUrl && <link itemProp="image" href={utils.site.fullUrl(imageUrl)} />}
        <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
          <link itemProp="url" href={utils.site.fullUrl(product.productUrl)} />
          {product.price && <meta itemProp="price" content={product.price} />}
          <meta itemProp="priceCurrency" content={utils.site.isNZ? 'NZD' : 'AUD'} />
        </div> */}

      <SiteLink className='productTile__link tile__content' to={productUrl}>
        <div>
          <img
            src={utils.site.resourcePath(imageUrl)}
            alt={product.productName}
          />
        </div>
        <div className='productTile__title'>
          {product.productName}
          <div className='productTile__inlineRating'>
            <div
              data-bv-show='inline_rating'
              data-bv-seo='false'
              data-bv-product-id={product.productCode}
            ></div>
          </div>
        </div>

        <div className='productTile__price'>
          {product.priceText ? product.priceText : ""}
        </div>
      </SiteLink>
      {/* </div> */}
    </div>
  );
}
