import React from 'react';
//import LoadingIcon from 'loading.svg';

export function Loading(props) {
  const [loaded, setLoaded] = React.useState(false);
  //console.log('loading', loaded)
  React.useEffect(() => {
    //console.log('loaded')
    setLoaded(true);
  }, [])
  return <div className={`sectionLoading ${loaded ? 'loaded' : '' }`}>
    <img src="/assets/loading.svg" alt="Loading ..." />
    {/* <img src={LoadingIcon} alt="Loading ..." /> */}
  </div>
}