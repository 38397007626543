import React from "react";
import env from "../../../env";
import { usePost } from "../../../components";
import { useForm } from "../../../form";
import { Button, ErrorMessage } from "../../../components";
import { getContactModel } from "./contactFormModel";
import { gtm } from "../../../lib/tracking";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const model = getContactModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), emailReceiver: emailReceiver };
      post.send(env.apiBase + "/api/contact/send", value);
      setSendingStatus("pending");
    });

    console.log("submitting");
  }

  // if (status === 'done') {
  //   //navigate(`/task/view/${taskId}`)
  //   window.location.reload();
  // }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <form className='contactForm'>
      <div className='form-row'>
        <div className='col-md-4 mb-2'>{render("name")}</div>
        <div className='col-md-4 mb-2'>{render("phone")}</div>
        <div className='col-md-4 mb-2'>{render("email")}</div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          {render("modelNumber")}
          <div
            style={{
              fontSize: "0.8rem",
              marginTop: "-0.7rem",
              marginLeft: "0.5rem",
              marginRight: "0.5rem",
              textAlign: "left",
            }}
          >
            This starts with 'VS' and ends with 'A' and can be found on the
            product packaging, instruction manual, or on the product itself.
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='col-md-12 mb-2'>{render("enquiry")}</div>
      </div>
      {/* <div className='form-row unsubscribed'>
        <div className='col-md-12 mb-2'>{render("unsubscribed")}</div>
      </div> */}
      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <p>
            By clicking 'Send Message' you accept the terms of our{" "}
            <a href='/privacy' target='_blank' rel='noopener noreferrer'>
              Privacy Policy
            </a>
            .
          </p>
          <div className='actions'>
            <Button onClick={onSubmit} status={post.status}>
              Send Message
            </Button>
            <ErrorMessage
              errors={form.errors}
              summaryMessage='Please review the errors.'
            />
            <ErrorMessage errors={post.errors} />
            {post.done() && (
              <span
                style={{
                  marginLeft: "1.5rem",
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Successfully sent!
              </span>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
