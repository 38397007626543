import React, { lazy, Suspense } from 'react';
//import { FrizzDefense } from './frizzDefense';
import { Loading } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
const FrizzDefense = lazy(() => import('./frizzDefense'));

export function FrizzDefenseLoading(props) {
  return <div>
    <Suspense fallback={<Loading />}>
     <FrizzDefense />
    </Suspense>
    <div className="frizzDefenseFooter">
      <ViewPartial/>
    </div>
  </div>
}