import React, { lazy, Suspense } from 'react';
//import { KeratinDefense } from './keratin';
import { Loading } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
const Keratin = lazy(() => import('./keratin'));

export function KeratinLoading(props) {
  return <div>
    <Suspense fallback={<Loading />}>
     <Keratin />
    </Suspense>
    <div className="keratinFooter">
      <ViewPartial/>
    </div>
  </div>
}