import facepaint from "facepaint";
import { css } from "@emotion/core";
import { darken } from "polished";
import { isNumeric } from "lib/utils-core/text";
import { getScreenSize } from "lib/animation/animationUtils";
import utils from "utils";

const breakpoints = [700, 1920, 2560];
const breakpointNames = ["small", "wide", "xwide"];

const breakpoints2 = [700, 1200, 1920, 2560];
const breakpointNames2 = ["small", "middle", "wide", "xwide"];

const mq = facepaint(breakpoints.map((bp) => `@media (min-width: ${bp}px)`));

const mqMin = {};
breakpointNames.forEach((bp, index) => (mqMin[bp] = `@media (min-width: ${breakpoints[index]}px)`));

const mqMin2 = {};
breakpointNames2.forEach((bp, index) => (mqMin2[bp] = `@media (min-width: ${breakpoints2[index]}px)`));

const mqMax = {};
breakpointNames.forEach((bp, index) => (mqMax[bp] = `@media (max-width: ${breakpoints[index] - 1}px)`));

const variables = {
  familyBase: '"Gotham",sans-serif',
  familyHeader: '"Gotham",sans-serif',
  familyDin: '"DIN Next LT Pro",serif',
  color: {
    bgColor: "#0c1728",
    gold: "#ac9a63",
    darkBlue: "#0c1728",
    lightBrown: "#f2e5d4",
    fontColor: "#444",
  },
};

function mqTransform(fn, ...args) {
  // console.log(typeof args, args);
  const ret = [];
  const arr = args.length === 1 && Array.isArray(args) ? args[0] : args;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === 0 || arr[i]) {
      ret[i] = fn(arr[i], i);
    }
  }
  return ret;
}

function getTransformX(value, inx) {
  // console.log(value, inx);
  return `translate(${getSize(value, inx)}, 0)`;
}

function getSize(value, inx) {
  if (isNumeric(value)) return inx === 0 ? remM(value) : rem(value);
  else return value;
}

const mixin = {
  r: (rem, baseSize = 40) => `calc(${rem} * var(--digitalSensorLuxeBase))`,
  rem: (px, baseSize = 40) => `calc(${px / baseSize} * var(--digitalSensorLuxeBase))`,
  remM: (px, baseSize = 20) => `calc(${px / baseSize} * var(--digitalSensorLuxeBase))`,
  px: (...args) => mqTransform(getSize, args),
  tx: (...args) => mqTransform(getTransformX, args),

  vh: (v) => `calc(${v} * var(--digitalSensorLuxeVhBase) / 100)`,
  vw: (v) => `calc(${v} * var(--digitalSensorLuxeVmBase) / 100)`,
};

const { rem, remM } = mixin;
const base = {
  h1: css({
    fontFamily: variables.familyBase,
    fontSize: rem(96),
    color: "black",
  }),
  h2: css({
    fontFamily: variables.familyBase,
    fontSize: rem(60),
    color: "white",
  }),
  h3: css({
    fontFamily: variables.familyBase,
    fontSize: rem(23),
    color: variables.color.gold,
  }),
  button: css({
    display: "inline-block",
    fontFamily: variables.familyHeader,
    fontSize: rem(20),
    fontWeight: "bold",
    background: variables.color.gold,
    padding: `0.4em 1em`,
    color: "white",
    transition: "all 0.3s",
    transform: "skewX(-12deg)",
    lineHeight: 1,

    span: {
      lineHeight: 1,
      textAlign: "center",
      display: "inline-block",
      transform: "skewX(12deg)",
      width: "100%",
    },

    "&: hover, &:focus": {
      textDecoration: "none",
      color: "white",
      background: darken(0.1, variables.color.gold),
    },
  }),
  mobileOnly: css({
    [mqMin.small]: {
      display: "none",
    },
  }),
  desktopOnly: css({
    [mqMax.small]: {
      display: "none",
    },
  }),
};

function viewportGuide() {
  try {
    return { width: document.querySelector(".viewportGuide").offsetWidth, height: document.querySelector(".viewportGuide").offsetHeight };
  } catch (e) {
    return getScreenSize();
  }
}

const isMobile = () => {
  var width = utils.css.screenWidth();
  return width < 700;
};
export { variables, mixin, breakpoints, mq, mqMin, mqMin2, mqMax, base, viewportGuide, isMobile };
