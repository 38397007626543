import React, { useEffect } from "react";
import { useRouter } from "../../components";
//import { navigate, usePath, useLocationChange } from 'raviger';
import { useHistory } from "../../components";
import utils from "../../utils";

export function TopHeaderQR(props) {
  const { pathname } = useRouter();
  const history = useHistory(pathname);

  const routeChanged = (path, history) => {
    if (typeof window !== `undefined`) {
      const hash = window.location.hash;
      const search = window.location.search;
      history.push(path + hash + search);
      if (hash) {
        scrollTo();
      }
    }
  };

  useEffect(() => {
    scrollTo();
  }, []);

  useEffect(() => {
    routeChanged(pathname, history);
    // eslint-disable-next-line
  }, [pathname]);

  function scrollTo() {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const anchor = utils.ui.findElement(hash);
        if (anchor) utils.ui.scrollTo(anchor, 500);
      }, 500);
    }
  }

  return (
    <>
      <header className="topNav" id="top">
        <div className="topNav__logo">
          <img
            src="/assets/logos/logo.png"
            alt="logo"
            className="topNav__logoImg"
          />
          <img
            src="/assets/logos/logo_orange.png"
            alt="logo"
            className="topNav__logoImg--var1"
          />
          <img
            src="/assets/logos/logo_grey.png"
            alt="logo"
            className="topNav__logoImg--var2"
          />
          <img
            src="/assets/logos/logo_gold.png"
            alt="logo"
            className="topNav__logoImg--var3"
          />
        </div>
      </header>
    </>
  );
}
