import React from 'react';
import env from '../../env';
import { enableForceUpdate, disableForceUpdate } from '../service-worker/swHelper';

export function AppVersion(props) {

  const [checkStatus, setCheckStatus] = React.useState('idle')
  //const [secs, setSecs] = React.useState(10)
  const ver = env.ver;
  const updateDate = env.updateDate;

  React.useEffect(() => {
    console.log(checkStatus);
    let timer = null;
    if (checkStatus === 'checking') {
      if (typeof window !== `undefined`) {
        enableForceUpdate();
        window.updateSW().then(function () {
          console.log('sw update called');
          setCheckStatus('checked')

          setTimeout(() => {
            console.log('timer called');
            disableForceUpdate();
            window.location.reload()
          }, 4000)
        })
      }
      // console.log('timer set');
      // let timer = setTimeout(() => {
      //   console.log('timer called');
      //   if (typeof window !== `undefined`) {
      //     window.location.reload()
      //   }
      // }, 5000)
      return () => { console.log('timer clear', timer); if (timer) clearTimeout(timer) }
    }
  }, [checkStatus])

  function checkUpdates(e) {
    if (typeof window !== `undefined`) {
      if (window.updateSW) {
        setCheckStatus('checking')
      } else {
        window.location.reload();
      }
    }
  }

  function refreshApp(e) {
    if (typeof window !== `undefined`) {
      window.location.reload();
    }
  }

  let button = null
  if (checkStatus === 'checking') {
    button = <button className="btn btn-primary" type="button">Checking App Version...</button>;
  } else if (checkStatus === 'checked') {
    button = <><button className="btn btn-primary" type="button" onClick={refreshApp}>Reloading App...</button> <div>(If this does't reload automatically, please click the above button)</div></>;
  } else {
    button = <button className="btn btn-primary" type="button" onClick={checkUpdates}>Update App (Refresh)</button>;
  }

  return (
    <div className="appInfo htmlPanel">
      <div className="appInfo__ver">
        <b>Version</b>: {ver} ({updateDate})
      </div>
      <div style={{ marginTop: '1rem' }}>
        {button}
      </div>
    </div>
  );
}

export function AppVersionPage(props) {
  return (
    <div style={{ margin: '5rem auto', width: '900px' }}>
      <AppVersion />
    </div>);
}