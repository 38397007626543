const config = {
  isProd: process.env.NODE_ENV === "production",
  locale: getLocale(),
  country: getCountry(),
  siteName: process.env.REACT_APP_SITENAME,
  siteCode: process.env.REACT_APP_SITECODE,
  apiBase: process.env.REACT_APP_API_ENDPOINT,
  resourceBase: process.env.REACT_APP_RESOURCE_BASEURL,
  baseOrigin: getBaseUrl(),
  cmsEndpoint: process.env.REACT_APP_CMS_ENDPOINT,
  ver: process.env.REACT_APP_VERSION,
  updateDate: process.env.REACT_APP_UPDATED_AT,

  bvClientId: process.env.REACT_APP_BAZZAR_CLIENT_ID,
  bvSiteId: process.env.REACT_APP_BAZZAR_SITE_ID,
  bvEnv: process.env.REACT_APP_BAZZAR_ENV,
  bvLocale: getLocale() === "nz" ? "en_NZ" : "en_AU",

  //facebook-domain-verification
  FaceBookDomainVerifiAU: "lpvr4yq2x5tpojjxq1nt4cgvutw4in",
  FaceBookDomainVerifiNZ: "dzc9nhns3cd8tbbb0vxzdzspqs9buu",

  instagramClientAccessToken:
    process.env.REACT_APP_INSTAGRAM_CLIENT_ACCESS_TOKEN,
  instagramUseFirst: process.env.REACT_APP_INSTAGRAM_USE_FIRST || "embed",
};

function getLocale() {
  return process.env.REACT_APP_COUNTRY_LOCALE || getCountry();
}

function getCountry() {
  if (typeof window !== `undefined`) {
    if (window.location.hostname.toLowerCase().indexOf(".nz") >= 0) return "nz";
    if (window.location.hostname.toLowerCase().indexOf("-nz") >= 0) return "nz";
    if (window.location.search === "?nz=true") return "nz";
  }
  return "au";
}

function getBaseUrl() {
  if (typeof window !== `undefined`) {
    return window.location.origin;
  }
  return process.env.GATSBY_BASEURL;
}

console.log(process.env, config);
export default {
  ...config,
};
