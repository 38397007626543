import utils from '../../utils';

export function sort(items, sortBy){
  function compare(a, b) {
    if (sortBy === 'az') return utils.array.caseInsensitiveCompare(a.productName, b.productName);
    if (sortBy === 'za') return -utils.array.caseInsensitiveCompare(a.productName, b.productName);
    if (sortBy === 'price-asc') return utils.array.compareIgnoreEmpty(true)(a.price, b.price);
    if (sortBy === 'price-desc') return utils.array.compareIgnoreEmpty(false)(a.price, b.price);
    return 0;
  }
  items.sort(compare);
}

export function sortSelections() {
  const sorts = [
    // { name: 'Product name (A-Z)', value: 'az' },
    // { name: 'Product name (Z-A)', value: 'za' },
    { label: 'Price - Highest first', value: 'price-desc' },
    { label: 'Price - Lowest first', value: 'price-asc' },
  ]
  return sorts;
}
