import React, { lazy, Suspense } from 'react';
import { Loading } from '../../components';
import { ViewPartial } from '../../cms/pages/partials/viewPartial';
const DigitalSensorRange = lazy(() => import('./digitalSensorRange'));

export function DigitalSensorLoading(props) {
  return <div>
    <Suspense fallback={<Loading />}>
     <DigitalSensorRange />
    </Suspense>
    <div className="digitalSensorFooter">
      <ViewPartial/>
    </div>
  </div>
}