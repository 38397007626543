import React, { useState, useEffect } from "react";
import { useRouter } from "../../../components";
import { useGet } from "../../../components";
import { Item } from "../../items/item";
import { NotFound } from "../../../components";
import env from "../../../env";
import { useStore } from "../../../store";
import { ErrorPopup, Loading } from "../../../components";
import { useInstagram } from "../../../components";
import { MetaTag } from "../../../components";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { gtm } from "../../../lib/tracking";
import { Helmet } from "react-helmet-async";
// import { loadTikTokJS } from "../../../lib/tiktok";

const log = (...args) => {
  //console.log(args);
};
const areEqual = (prevProps, nextProps) => {
  return prevProps.pageData === nextProps.pageData;
};
const PageMemo = React.memo(Page, areEqual);

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [data, setData] = useState(null);
  // const [embedTypes, setEmbedTypes] = useState(null);
  //const path = usePath();
  //log('view page', path);
  const { pathname, Redirect } = useRouter();
  useInstagram();

  const get = useGet();
  log("0. viewpage ready");

  useEffect(() => {
    log("*. start loading");
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    if (data) {
      dispatch({ type: "SET_PAGE", payload: data.data });
      dispatch({ type: "SET_MENU", payload: data.menu });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    utils.site.callMCPoopu();
  }, []);

  // useEffect(() => {
  //   if (embedTypes && embedTypes.tiktok) {
  //     loadTikTokJS();
  //   }
  // }, [embedTypes]);

  if (get.loading()) {
    return <Loading />;
  } else if (get.hasErrors()) {
    return <ErrorPopup title='Loading Error' errors={get.errors} />;
  }

  //log(router.pathname);
  //log(get.loading());
  log("1. viewpage component", pageLoaded, get.response);

  console.log("get.response", get.response);

  const pageData = get.response.data;
  //const menuData = get.response.menu;
  const redirectTo = get.response.redirectTo;
  const datePublishedForBlog = get.response.datePublishedForBlog;

  const cmsOption = {};
  log(pageLoaded, !pageLoaded && !!pageData);
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log("2. dispatching page & menu");
    setPageLoaded(true);
    setData(get.response);
    // setEmbedTypes(getEmbedTypes(pageData));
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (redirectTo) {
    if (redirectTo.indexOf("http") >= 0 && typeof window !== `undefined`) {
      return (window.location.href = redirectTo);
    } else {
      return <Redirect to={redirectTo} />;
    }
  } else if (!pageLoaded && !pageData) {
    setPageLoaded(true);
    setData(get.response);
    return <NotFound />;
  }

  log("3. call rendering memo");
  if (pageData)
    return (
      <PageMemo
        pageData={pageData}
        cmsOption={cmsOption}
        datePublishedForBlog={datePublishedForBlog}
      />
    );
  else return <NotFound />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  const datePublishedForBlog = props.datePublishedForBlog;
  //const description = cmsUtils.payload(pageData, "Description");

  const country = utils.site.isNZ ? "New Zealand" : "Australia";

  const heroImageUrl = utils.site.fullUrl(
    cmsUtils.payload(pageData, "ImageUrl")
  );
  cmsOption.pageData = pageData;
  // document.title = pageData.pageTitle;

  log("4. page rendering", pageData, cmsOption);
  const sectionItems = pageData.sections.map((section, index) => {
    return (
      <Section
        item={section}
        key={section.sectionName + index}
        cmsOption={cmsOption}
      ></Section>
    );
  });

  const homeStructuredData = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "VS Sassoon",
    "url":
      country === "New Zealand"
        ? "https://www.vssassoon.co.nz"
        : "https://www.vssassoon.com.au",
    "logo":
      country === "New Zealand"
        ? "https://www.vssassoon.co.nz/assets/logos/logo.png"
        : "https://www.vssassoon.com.au/assets/logos/logo.png",
    "contactPoint":
      country === "New Zealand"
        ? {
            "@type": "ContactPoint",
            "telephone": "+64 800 266 247",
            "contactType": "Customer Service",
            "areaServed": "NZ",
            "availableLanguage": "English",
            "url": "https://www.vssassoon.co.nz/contact",
          }
        : {
            "@type": "ContactPoint",
            "telephone": "+61 1800 650 263",
            "contactType": "Customer Service",
            "areaServed": "AU",
            "availableLanguage": "English",
            "url": "https://www.vssassoon.com.au/contact",
          },
    "address":
      country === "New Zealand"
        ? {
            "@type": "PostalAddress",
            "streetAddress": "PO Box 251159",
            "addressLocality": "Pakuranga",
            "addressRegion": "Auckland",
            "postalCode": "2140",
            "addressCountry": "New Zealand",
          }
        : {
            "@type": "PostalAddress",
            "streetAddress": "PO Box 146",
            "addressLocality": "Terrey Hills",
            "addressRegion": "NSW",
            "postalCode": "2084",
            "addressCountry": "Australia",
          },
    "sameAs": [
      "https://www.facebook.com/VSSassoon",
      "https://instagram.com/vs_sassoon",
      "https://www.youtube.com/vssassoon",
      "https://www.tiktok.com/@vs_sassoon",
    ],
  };
  const articleStructuredData = {
    "@context": "https://schema.org/",
    "@type": "BlogPosting",
    "headline": pageData.pageTitle,
    //"description": description,
    "image": heroImageUrl,
    "datePublished":
      datePublishedForBlog && datePublishedForBlog !== ""
        ? datePublishedForBlog
        : "",
    "dateModified": pageData.dateUpdated,
    "author": {
      "@type": "Organization",
      "name": "VS Sassoon",
      "url":
        country === "New Zealand"
          ? "https://www.vssassoon.co.nz"
          : "https://www.vssassoon.com.au",
    },
  };

  return (
    <>
      <MetaTag data={getMetaTag(pageData)} />
      {(pageData.pageUrl === "/" || pageData.pageUrl === "/contact") && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(homeStructuredData)}
          </script>
        </Helmet>
      )}
      {pageData.pageTypeCode && pageData.pageTypeCode === "BlogPost" && (
        <Helmet>
          <script type='application/ld+json'>
            {JSON.stringify(articleStructuredData)}
          </script>
        </Helmet>
      )}

      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    </>
  );
}

// function getEmbedTypes(pageData) {
//   const embedTypes = { tiktok: false };
//   const embedDataList = pageData?.embedDataList;
//   embedTypes.tiktok = utils.array.find(embedDataList, (x) => (x.code || "").indexOf("tiktok.com") >= 0);

//   return embedTypes;
// }

function Section(props) {
  log("5. section rendering");
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0) return <></>;

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));
  return <>{subItemsElts}</>;
}

function getMetaTag(pageData) {
  const country = utils.site.isNZ ? "New Zealand" : "Australia";
  const siteName = env.siteName + " " + country;

  return {
    title: pageData.pageTitle + " | " + siteName,
    description: cmsUtils.payload(pageData, "Description"),
    keywords: cmsUtils.payload(pageData, "Keyword"),
    heroImageUrl: utils.site.fullUrl(cmsUtils.payload(pageData, "ImageUrl")),
    language: "English",
    country: country,
    siteName: siteName,
    url: utils.site.fullUrl(pageData.pageUrl),
    alternates: utils.site.getAlternates(pageData.pageUrl),

    //facebook-domain-verification
    faceBookDomainVerifi:
      country === "New Zealand"
        ? env.FaceBookDomainVerifiNZ
        : env.FaceBookDomainVerifiAU,
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}
