import React from 'react';
import { Item } from '../../items/item';
//import cmsUtils from '../../utils/cmsUtils';
import  utils from '../../../utils';
import './style.scss';

export function LinkTiles(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  //const heightSet = cmsUtils.payload(item, 'HeightSet') || 'standard';

  const tiles = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  const cssClass = utils.classNames('cms_item', 'linkTiles', item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {tiles}
    </div>
  );
}