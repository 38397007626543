/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useLayoutEffect } from 'react';

export function InstagramFeedEmbed(props){
    
    useLayoutEffect(() => {
        const existingScript = document.getElementById('EmbedSocialHashtagScript');
        if(existingScript){
            document.body.removeChild(existingScript);
        }
        const script = document.createElement('script');
        script.src = 'https://embedsocial.com/cdn/ht.js';
        script.id = 'EmbedSocialHashtagScript';
        document.body.appendChild(script);

        // eslint-disable-next-line
    }, []);

    return(
        <div className="embedsocial-hashtag" data-ref="1b7b0e1ab09b2bdc92e7bcb9305a4b90615e3fe6"></div>
    )

}