import React, { lazy, Suspense } from "react";
//import { UnboundDefense } from './unbound';
import { Loading } from "../../components";
//import { NotFound } from '../../components';
import { ViewPartial } from "../../cms/pages/partials/viewPartial";
import { Helmet } from "react-helmet-async";

const HydroSmooth = lazy(() => import("./hydroSmooth"));
//const HydroSmoothAnimation = lazy(() => import('./hydroSmoothAnimation'));

export function HydroSmoothLoading(props) {

  const schema = {
	"@context": "https://schema.org",
    "@type": "ItemList",
    "url": "https://www.vssassoon.com.au/hydro-smooth",
    "numberOfItems": "3",
    "itemListElement": [
			{
				"@type": "Product", 
				"name": "HYDRO SMOOTH 5-IN-1 AIR STYLER",
				"description": "Dry, smooth, straighten, shape & create envious volume with this 5-in-1 hair dryer brush that will transform your locks to the smoothest, softest, bounciest style. With 5 styling attachments, you can change it, smooth it, style it & mix it up, while the Advanced Plasma Conditioning Ions hydrate & replenish dull, dry hair, for healthier, glossier, smoother & frizz-free hair after consistent use.",
				"sku": "VSHA2774A",
				"url": "https://www.vssassoon.com.au/hydro-smooth",
				"brand": {
				"@type": "Brand", 
					"name": "VS Sassoon"
				},
					"category": "Stylers",
				"image": [
					"https://www.vssassoon.com.au/dist/hydroSmooth/images/groupshot-styler.jpg",
					"https://www.vssassoon.com.au/dist/hydroSmooth/images/styler-anim-comp.gif",
					"https://img.youtube.com/vi/d-qgP51ODaw/maxresdefault.jpg"
				],
				"offers": {
					"@type": "Offer",
					"priceCurrency": "AUD",
					"price": "129.95",
					"availability": "https://schema.org/InStock",
				}
			},
			{
				"@type": "Product", 
				"name": "HYDRO SMOOTH FAST DRY",
				"description": "Coloured, hard-to-manage, moisture-stripped hair? Hydrate & replenish dull, dry hair with the Hydro Smooth Fast Dry's Advanced Plasma Conditioning Ions. The proven technology that leaves hair in better condition so that it feels healthier, glossier, smoother & frizz-free after consistent use.",
				"sku": "VSD5573A",
				"url": "https://www.vssassoon.com.au/hydro-smooth",
				"brand": {
					"@type": "Brand", 
					"name": "VS Sassoon"
				},
				"category": "Dryers",
				"image": [
					"https://www.vssassoon.com.au/dist/hydroSmooth/images/fast-dryer-anim.gif",
					"https://www.vssassoon.com.au/dist/hydroSmooth/images/dryer-table.png",
					"https://img.youtube.com/vi/fK6xbSXWIB0/maxresdefault.jpg"
				],
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.7",
					"ratingCount": "88"
				},
				"offers": {
					"@type": "Offer",
					"priceCurrency": "AUD",
					"price": "99.95",
					"availability": "https://schema.org/InStock",
				}
			},
			{
				"@context": "https://schema.org/", 
				"@type": "Product", 
				"name": "HYDRO SMOOTH STRAIGHT & CURL",
				"description": "Coloured, hard-to-manage, moisture-stripped hair? Hydrate & replenish while you style for perfectly straight or curled results with Hydro Smooth Straight & Curl's Advanced Plasma Conditioning Ions. The proven technology that leaves hair healthier, glossier, smoother & frizz-free after consistent use.",
				"sku": "VSS3290A",
				"url": "https://www.vssassoon.com.au/hydro-smooth",
				"brand": {
					"@type": "Brand", 
					"name": "VS Sassoon"
				},
				"category": "Stylers",
				"image": [
					"https://www.vssassoon.com.au/dist/hydroSmooth/images/styler.gif",
					"https://www.vssassoon.com.au/dist/hydroSmooth/images/styler-table.png",
					"https://img.youtube.com/vi/4mZBU2V6TJo/maxresdefault.jpg"
				],
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "3.0",
					"ratingCount": "27"
				},
				"offers": {
					"@type": "Offer",
					"priceCurrency": "AUD",
					"price": "89.95",
					"availability": "https://schema.org/InStock",
				}
			}
		]	
	}
  
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      </Helmet>
      <div>
        <Suspense fallback={<Loading />}>
          <HydroSmooth />
        </Suspense>
        <div className="hydroSmoothFooter">
          <ViewPartial
            pageOptions={{
              imagePath:
                "/dist/hydroSmooth/images/love-your-style-banner-hydro2.jpg",
            }}
          />
        </div>
      </div>
    </>
  );
}