import React, { lazy, Suspense } from "react";
import { Loading } from "../../components";
import { ViewPartial } from "../../cms/pages/partials/viewPartial";
const DigitalSensorLuxe = lazy(() => import("./digitalSensorLuxe"));

export function DigitalSensorLuxeLoading(props) {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <DigitalSensorLuxe />
      </Suspense>
      <div className="digitalSensorLuxeFooter">
        <ViewPartial />
      </div>
    </div>
  );
}
