import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';

import env from '../../../env';
import { useGet } from '../../../components';
import { ErrorPopup, Loading } from '../../../components';

import { Tile } from '../../../site/product/productTile';

export function ProductTiles(props) {
  const item = props.item;
  //const cmsOption = props.cmsOption || {};

  //console.log('item', item);
  const productCodes = getProductCodes(cmsUtils.payload(item, 'ProductList'))
  //console.log(productCodes)
  const cssClass = utils.classNames('cms_item', 'productListByCodes', item.cssClass || item.anchorName || '');

  return <div className={cssClass}>
    {productCodes && productCodes.length > 0 && <TilesFromCodes productCodes={productCodes}/>}
  </div>
}

function getProductCodes(str){
  const list = (str || '').replace(/\r\n/g, '\n').split('\n');
  return list.filter(x => !!x)
}

export function TilesFromCodes({productCodes}) {
  const get = useGet();
  React.useEffect(() => {
    get.send(env.apiBase + '/api/product/getProductByCodes?productCodes=' + productCodes.join(','));
    // eslint-disable-next-line
  }, []);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  console.log(get)
  const data = get.response;

  if (!data || data.length === 0) return <div></div>
  return (
    <div className="productList">
      {data.map((product, index) =>
        <Tile product={product} key={product.productCode}></Tile>
      )}
    </div>
  );
}