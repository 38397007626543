import React from 'react';
//import utils from '../../utils';
import env from '../../env';
import { loadJs, loadedJs } from '../../lib/net-loader';

export function useBV(prd, { showReview }) {
  const [product] = React.useState(prd);

  React.useEffect(() => {
    // make sure whether the js has been loaded for every product page loading.
    console.log('[useBV]--useEffect, js loading')
    const url = `https://apps.bazaarvoice.com/deployments/${env.bvClientId}/${env.bvSiteId}/${env.bvEnv}/${env.bvLocale}/bv.js`
    if (!loadedJs(url)) {
      loadJs(url).then(data => {
        console.log('[BV] script loaded ', data);
      })
    }
  }, [product])

  React.useEffect(() => {
    console.log('[useBV]--useEffect')

    const reviewClicked = function (e) {
      console.log('[BV] show review panel')
      showReview(e);
    }

    const bvCallback = (BV) => {
      console.log('[BV] callback loaded', BV);

      if (BV && BV.swat_reviews){
        BV.swat_reviews.on('show', reviewClicked);
      }

      // BV.rating_summary.on('show', function () {
      //   console.log('rating_summary show')
      // });
    }
    if (typeof window !== `undefined`) {
      if (window.BV) {
        bvCallback(window.BV)
      } else {
        window.bvCallback = bvCallback
      }
    }
    return () => {
      console.log('[BV] clear events bound')
      if (typeof window !== `undefined`) {
        if (window.BV && window.BV.swat_reviews)
          window.BV.swat_reviews.off('show', reviewClicked);
        window.bvCallback = null
      }
    }
  }, [showReview])

  //https://knowledge.bazaarvoice.com/wp-content/conversations/en_US/Collect/bvpixel.html#events-captured-by-bv-pixel
  const sendEvent = (type, label, value, options = {}) => {
    console.log('[BV] event', type, label, value)
    if (typeof window !== `undefined`) {
      if (!window.BV) { console.error('No BV object'); return; }
      const BV = window.BV
      BV.pixel.trackConversion({
        ...{
          "type": type,
          "label": label,
          "value": value
        }, ...options
      });
    }
  }

  const sendTransactionEvent = (currency, orderId, total, items, options = {}) => {
    console.log('[BV] transaction event', currency, orderId, total, items)
    if (typeof window !== `undefined`) {
      if (!window.BV) { console.error('No BV object'); return; }
      const BV = window.BV
      BV.pixel.trackTransaction({
        ...{
          "currency": currency,
          "orderId": orderId,
          "total": total,
          "items": items
        }, ...options
      });
    }
  }

  return { sendEvent, sendTransactionEvent }
}