import React from 'react';
import Select from 'react-select'
import utils from '../../utils'

export function FilterDropdown({ title, isMulti = false, items, value, onChange }) {
  //const currentSelection = utils.array.find(items, x => x.value === value);
  let currentSelection = null;

  if(Array.isArray(value)){
    //currentSelection = utils.array.find(items, x => x.value === value);
    currentSelection = [];
    value.forEach(el => {
      const _selectedItem = utils.array.find(items, x => x.value === el);
      currentSelection.push(_selectedItem);
    });
  }
  else{
    currentSelection = utils.array.find(items, x => x.value === value);
    
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      textAlign: 'left'
    })
  }
  const customTheme = theme => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary25: '#eee',
      primary: '#404040',
    },
  })

  function onChange_(selected) {
    //console.log(selected)
    if (isMulti){
      onChange(
        (selected || []).map(x => x.value)
      );
    }
    else
      onChange(selected ? selected.value : '');
  }

  return (
    <Select options={items}
      styles={customStyles}
      placeholder={title}
      isMulti={isMulti}
      value={currentSelection}
      onChange={onChange_}
      isSearchable={false}
      theme={customTheme} />
  )
}
