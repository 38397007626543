import React from "react";

function SvgNarrowLeft(props) {
  return (
    <svg viewBox="0 0 100 240" {...props}>
      <path
        fill="currentColor"
        d="M76 20L26 120l50 100v-10l-44-90 44-90z"
      />
    </svg>
  );
}

export default SvgNarrowLeft;
