import React, { lazy, Suspense } from 'react';
import { Loading } from '../../components';
const Warranty = lazy(() => import('./warrantyForm'));

export function WarrantyLazy(props) {
  return <div>
    <Suspense fallback={<Loading />}>
      <Warranty />
    </Suspense>
  </div>
}