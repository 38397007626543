import React from "react";
import utils from "../../utils";
import * as yt from "./iframeApi";

export function YoutubeEmbedByLink({ url, title }) {
  const videoId = yt.getYoutubeCode(url);
  //const posterImageUrl = yt.thumbnailMax(videoId);
  const playerVars = {
    enablejsapi: 1,
    //'autoplay': 1,
    controls: 1,
    loop: 0,
    showinfo: 0,
    rel: 0,
    modestbranding: 1,
    //'origin': typeof window !== `undefined` && window.location.origin,
    allowfullscreen: true,
    wmode: "transparent",
  };

  return (
    <div className="youtubeEmbedByLink">
      <iframe
        className="youtubeEmbed__iframe"
        width="640"
        height="585"
        title={title || "Youtube video"}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        src={`https://www.youtube.com/embed/${videoId}?${utils.url.toQueryString(
          playerVars
        )}`}
        frameBorder="0"
        allowFullScreen
        style={{
          background: "#FFF",
          border: 0,
          borderRadius: "3px",
          boxShadow: "0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)",
          margin: "1px",
          maxWidth: "540px",
          minWidth: "326px",
          padding: "0",
          width: "99.375%",
          // eslint-disable-next-line
          width: "-webkit-calc(100% - 2px)",
          // eslint-disable-next-line
          width: "calc(100% - 2px)",
        }}
      ></iframe>
    </div>
  );
}
